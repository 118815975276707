import { Record, Union } from "./.fable/fable-library.3.1.11/Types.js";
import { record_type, list_type, tuple_type, option_type, union_type, class_type, string_type } from "./.fable/fable-library.3.1.11/Reflection.js";
import { EntityType$reflection } from "./Shared/Entity.fs.js";
import { contains, count, FSharpSet__get_IsEmpty, ofList, FSharpSet__Add, FSharpSet__Remove, FSharpSet__Contains, empty } from "./.fable/fable-library.3.1.11/Set.js";
import { comparePrimitives } from "./.fable/fable-library.3.1.11/Util.js";
import { FSharpResult$2 } from "./.fable/fable-library.3.1.11/Choice.js";
import { Cmd_none } from "./.fable/Fable.Elmish.3.1.0/cmd.fs.js";
import { cons, singleton, empty as empty_1, toArray, ofArray, filter as filter_1, map } from "./.fable/fable-library.3.1.11/List.js";
import { entityMetaFromType, GetQuery } from "./Data.fs.js";
import { join, printf, toText, isNullOrEmpty } from "./.fable/fable-library.3.1.11/String.js";
import { defaultArg, some, map as map_1 } from "./.fable/fable-library.3.1.11/Option.js";
import { SpinnerProps, Spinner, ListProp$1, FabricList, SearchBoxProp, SearchBox } from "./fabric/FabricUI.fs.js";
import { Browser_Types_Event__Event_get_Value } from "./.fable/Fable.React.7.3.0/Fable.React.Extensions.fs.js";
import { CommandBar_CommandBarItemProps, Styles, CommandBar_CommandBarProps, CommandBar_CommandBar } from "./fabric/CommandBar.fs.js";
import { CSSProp } from "./.fable/Fable.React.7.3.0/Fable.React.Props.fs.js";
import { getTheme } from "@uifabric/styling";
import { Styles as Styles_1, StackProps, Stack } from "./fabric/Stack.fs.js";
import * as react from "react";
import { itemCell } from "./fabric/MergeStyle.fs.js";
import { Props, Checkbox } from "./fabric/Checkbox.fs.js";
import { Props as Props_1, Modal } from "./fabric/Modal.fs.js";
import { Props as Props_2, DefaultButton } from "./fabric/Button.fs.js";
import { errorModal } from "./Views.fs.js";
import { append, singleton as singleton_1, delay, toList } from "./.fable/fable-library.3.1.11/Seq.js";

export class Generating extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Nope", "Waiting", "GotData", "Errored"];
    }
}

export function Generating$reflection() {
    return union_type("ExportPage.Generating", [], Generating, () => [[], [], [["Item", string_type]], [["Item", class_type("System.Exception")]]]);
}

export class Model extends Record {
    constructor(session, user, ai, entitiesType, entities, generating, entitiesChecked, Filter) {
        super();
        this.session = session;
        this.user = user;
        this.ai = ai;
        this.entitiesType = entitiesType;
        this.entities = entities;
        this.generating = generating;
        this.entitiesChecked = entitiesChecked;
        this.Filter = Filter;
    }
}

export function Model$reflection() {
    return record_type("ExportPage.Model", [], Model, () => [["session", class_type("UserAgentApplication.UserAgentApplication")], ["user", class_type("Account.Account")], ["ai", class_type("AI.IApplicationInsights")], ["entitiesType", EntityType$reflection()], ["entities", list_type(tuple_type(class_type("System.Guid"), option_type(string_type)))], ["generating", Generating$reflection()], ["entitiesChecked", class_type("Microsoft.FSharp.Collections.FSharpSet`1", [class_type("System.Guid")])], ["Filter", Search$reflection()]]);
}

export class Search extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["NoSearch", "Filter"];
    }
}

export function Search$reflection() {
    return union_type("ExportPage.Search", [], Search, () => [[], [["Item", tuple_type(string_type, list_type(tuple_type(class_type("System.Guid"), option_type(string_type))))]]]);
}

export function initialModel(session, user, ai, devices, entitiesType) {
    return new Model(session, user, ai, entitiesType, devices, new Generating(0), empty({
        Compare: (x, y) => comparePrimitives(x, y),
    }), new Search(0));
}

export class Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Export", "ExportAll", "Exported", "ClearAll", "SelectAll", "Toggle", "DismissDownload", "Ignore", "Reload", "Filter", "Clear"];
    }
}

export function Msg$reflection() {
    return union_type("ExportPage.Msg", [], Msg, () => [[], [], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [string_type, class_type("System.Exception")], FSharpResult$2, () => [[["ResultValue", string_type]], [["ErrorValue", class_type("System.Exception")]]])]], [], [], [["Item", class_type("System.Guid")]], [], [], [], [["Item", string_type]], []]);
}

export function Update(msg, model) {
    let filter;
    if (msg.tag === 10) {
        return [new Model(model.session, model.user, model.ai, model.entitiesType, model.entities, model.generating, model.entitiesChecked, new Search(0)), Cmd_none()];
    }
    else if (msg.tag === 7) {
        return [initialModel(model.session, model.user, model.ai, model.entities, model.entitiesType), Cmd_none()];
    }
    else if (msg.tag === 8) {
        const value = window.location.reload();
        return [model, Cmd_none()];
    }
    else if (msg.tag === 6) {
        return [new Model(model.session, model.user, model.ai, model.entitiesType, model.entities, new Generating(0), model.entitiesChecked, model.Filter), Cmd_none()];
    }
    else if (msg.tag === 5) {
        return [FSharpSet__Contains(model.entitiesChecked, msg.fields[0]) ? (new Model(model.session, model.user, model.ai, model.entitiesType, model.entities, model.generating, FSharpSet__Remove(model.entitiesChecked, msg.fields[0]), model.Filter)) : (new Model(model.session, model.user, model.ai, model.entitiesType, model.entities, model.generating, FSharpSet__Add(model.entitiesChecked, msg.fields[0]), model.Filter)), Cmd_none()];
    }
    else if (msg.tag === 4) {
        return [new Model(model.session, model.user, model.ai, model.entitiesType, model.entities, model.generating, ofList(map((tuple) => tuple[0], model.entities), {
            Compare: (x, y) => comparePrimitives(x, y),
        }), model.Filter), Cmd_none()];
    }
    else if (msg.tag === 3) {
        return [new Model(model.session, model.user, model.ai, model.entitiesType, model.entities, model.generating, empty({
            Compare: (x_1, y_1) => comparePrimitives(x_1, y_1),
        }), model.Filter), Cmd_none()];
    }
    else if (msg.tag === 0) {
        return [new Model(model.session, model.user, model.ai, model.entitiesType, model.entities, new Generating(1), model.entitiesChecked, model.Filter), GetQuery(entityMetaFromType(model.entitiesType).url, model.session, (arg0) => (new Msg(2, arg0)), model.entitiesChecked)];
    }
    else if (msg.tag === 1) {
        return [new Model(model.session, model.user, model.ai, model.entitiesType, model.entities, new Generating(1), model.entitiesChecked, model.Filter), GetQuery(entityMetaFromType(model.entitiesType).url, model.session, (arg0_1) => (new Msg(2, arg0_1)), empty({
            Compare: (x_2, y_2) => comparePrimitives(x_2, y_2),
        }))];
    }
    else if (msg.tag === 2) {
        if (msg.fields[0].tag === 1) {
            model.ai.trackException({
                exception: msg.fields[0].fields[0],
            });
            return [new Model(model.session, model.user, model.ai, model.entitiesType, model.entities, new Generating(3, msg.fields[0].fields[0]), model.entitiesChecked, model.Filter), Cmd_none()];
        }
        else {
            return [new Model(model.session, model.user, model.ai, model.entitiesType, model.entities, new Generating(2, URL.createObjectURL(new Blob([msg.fields[0].fields[0]], {
                endings: "native",
                type: "application/json",
            }))), model.entitiesChecked, model.Filter), Cmd_none()];
        }
    }
    else if (isNullOrEmpty(msg.fields[0])) {
        return [new Model(model.session, model.user, model.ai, model.entitiesType, model.entities, model.generating, model.entitiesChecked, new Search(0)), Cmd_none()];
    }
    else {
        return [new Model(model.session, model.user, model.ai, model.entitiesType, model.entities, model.generating, model.entitiesChecked, new Search(1, [msg.fields[0], filter_1((filter = msg.fields[0].toLocaleLowerCase(), (i) => {
            const maybeF = map_1((j) => (j.toLocaleLowerCase().indexOf(filter) >= 0), i[1]);
            return (maybeF == null) ? false : maybeF;
        }), model.entities)])), Cmd_none()];
    }
}

export function searchbox(model, et, dispatch, unitVar3) {
    let value;
    const matchValue = model.Filter;
    value = ((matchValue.tag === 1) ? matchValue.fields[0][0] : "");
    const meta = entityMetaFromType(et);
    return SearchBox(ofArray([new SearchBoxProp(0, toText(printf("Search %s by name"))(meta.name)), new SearchBoxProp(1, value), new SearchBoxProp(4, {
        root: {
            ["align-self"]: "center",
        },
    }), new SearchBoxProp(3, () => {
        dispatch(new Msg(10));
    }), new SearchBoxProp(2, (ev) => {
        dispatch(new Msg(9, Browser_Types_Event__Event_get_Value(ev)));
    })]));
}

export function commandBar(model, dispatch) {
    return CommandBar_CommandBar(ofArray([new CommandBar_CommandBarProps(6, "CommandBar"), new CommandBar_CommandBarProps(8, new Styles(ofArray([new CSSProp(17, "white"), new CSSProp(224, "20px"), new CSSProp(225, "20px"), new CSSProp(42, "1px solid"), new CSSProp(43, getTheme().palette.neutralQuaternaryAlt)]))), new CommandBar_CommandBarProps(0, toArray(ofArray([CommandBar_CommandBarItemProps("Export", "DownloadDocument", void 0, false, "export ", (_arg1) => {
        dispatch(new Msg(0));
    }, void 0, FSharpSet__get_IsEmpty(model.entitiesChecked), void 0), CommandBar_CommandBarItemProps("Export All", "DownloadDocument", void 0, false, "export all", (_arg2) => {
        dispatch(new Msg(1));
    }, void 0, false, void 0), CommandBar_CommandBarItemProps("Select All", "SelectAll", void 0, false, "SelectAll ", (_arg3) => {
        dispatch(new Msg(4));
    }, void 0, false, void 0), CommandBar_CommandBarItemProps("Clear Selection ", "ClearSelection", void 0, false, "ClearSelction", (_arg4) => {
        dispatch(new Msg(3));
    }, void 0, FSharpSet__get_IsEmpty(model.entitiesChecked), void 0), CommandBar_CommandBarItemProps("Search stuff", "", void 0, false, "searchdevices", void 0, () => searchbox(model, model.entitiesType, dispatch, void 0), false, void 0)]))), new CommandBar_CommandBarProps(1, toArray(empty_1()))]));
}

export function viewList(model, dispatch) {
    let matchValue;
    return Stack(ofArray([new StackProps(5, true), new StackProps(13, new Styles_1(ofArray([new CSSProp(272, "auto"), new CSSProp(189, "100%")])))]), singleton(FabricList(ofArray([new ListProp$1(0, toArray((matchValue = model.Filter, (matchValue.tag === 1) ? matchValue.fields[0][1] : model.entities))), new ListProp$1(1, (item) => {
        let copyOfStruct;
        return react.createElement("div", {
            className: itemCell.itemCell,
            onClick: (_arg1) => {
                dispatch(new Msg(5, item[0]));
            },
        }, Stack(ofArray([new StackProps(4, "center"), new StackProps(3, "start"), new StackProps(1, true)]), ofArray([Checkbox(ofArray([new Props(2, FSharpSet__Contains(model.entitiesChecked, item[0])), new Props(1, (_arg2) => {
            dispatch(new Msg(5, item[0]));
        })])), Stack(ofArray([new StackProps(1, true), new StackProps(7, some(1)), new StackProps(3, "space-between")]), singleton(react.createElement("div", {}, defaultArg(item[1], (copyOfStruct = item[0], copyOfStruct)))))])));
    })]))));
}

export function modal(model, dispatch, wrapped) {
    const fileName = (count(model.entitiesChecked) > 5) ? entityMetaFromType(model.entitiesType).name : join("-", map((tupledArg_1) => defaultArg(tupledArg_1[1], "NoName"), filter_1((tupledArg) => contains(tupledArg[0], model.entitiesChecked), model.entities)));
    const matchValue = model.generating;
    switch (matchValue.tag) {
        case 2: {
            return cons(Modal(ofArray([new Props_1(0, false), new Props_1(2, (_arg4) => {
                dispatch(new Msg(6));
            }), new Props_1(1, true)]), [react.createElement("div", {
                style: {
                    margin: "100px",
                },
            }, react.createElement("h1", {
                style: {
                    margin: "10px",
                },
            }, "Your data is ready"), Stack(ofArray([new StackProps(8, 10), new StackProps(1, true), new StackProps(3, "center")]), singleton(DefaultButton(ofArray([new Props_2(2, "Download"), new Props_2(10, false), new Props_2(11, "_blank"), new Props_2(6, matchValue.fields[0]), new Props_2(12, fileName), new Props_2(1, (_arg5) => {
                dispatch(new Msg(6));
            })])))))]), wrapped);
        }
        case 3: {
            return errorModal(matchValue.fields[0].message, wrapped, dispatch, new Msg(8), new Msg(7));
        }
        case 0: {
            return wrapped;
        }
        default: {
            return cons(Modal(ofArray([new Props_1(0, false), new Props_1(2, (_arg3) => {
                dispatch(new Msg(6));
            }), new Props_1(1, true)]), [react.createElement("div", {
                style: {
                    margin: "100px",
                },
            }, react.createElement("h1", {
                style: {
                    margin: "10px",
                },
            }, "Generating your data "), react.createElement("div", {
                style: {
                    margin: "30px",
                },
            }, Spinner(singleton(new SpinnerProps(0, "")), empty_1())), Stack(ofArray([new StackProps(8, 10), new StackProps(1, true), new StackProps(3, "center")]), empty_1()))]), wrapped);
        }
    }
}

export function view(model, dispatch) {
    const meta = entityMetaFromType(model.entitiesType);
    return Stack(ofArray([new StackProps(0, "analysisCard"), new StackProps(5, true), new StackProps(4, "space-between"), new StackProps(13, new Styles_1(ofArray([new CSSProp(189, "100%"), new CSSProp(272, "hidden")])))]), modal(model, dispatch, singleton(Stack(ofArray([new StackProps(5, true), new StackProps(4, "space-between"), new StackProps(13, new Styles_1(empty_1()))]), toList(delay(() => singleton_1(Stack(ofArray([new StackProps(13, new Styles_1(ofArray([new CSSProp(224, "20px"), new CSSProp(225, "20px"), new CSSProp(221, "5px")]))), new StackProps(5, true)]), toList(delay(() => append(singleton_1(react.createElement("h2", {}, meta.name)), delay(() => append(singleton_1(react.createElement("div", {}, "Here you can retrieve all the data into a single json file for further analysis using your favorite tool!")), delay(() => singleton_1(Stack(ofArray([new StackProps(5, true), new StackProps(4, "space-between"), new StackProps(13, new Styles_1(ofArray([new CSSProp(272, "auto"), new CSSProp(189, "100%")])))]), toList(delay(() => append(singleton_1(commandBar(model, dispatch)), delay(() => singleton_1(viewList(model, dispatch))))))))))))))))))))));
}

