import { format, printf, toText } from "./.fable/fable-library.3.1.11/String.js";

export function apiURL(s) {
    return toText(printf("/api%s"))(s);
}

export function entityF(entityName) {
    return printf(format("/{0}/%O", entityName));
}

