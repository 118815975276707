import { Record } from "../.fable/fable-library.3.1.11/Types.js";
import { class_type, record_type, bool_type, option_type, string_type } from "../.fable/fable-library.3.1.11/Reflection.js";

export class AuthOptions extends Record {
    constructor(clientId, authority, validateAuthority, redirectUri, postLogoutRedirectUri, navigateToLoginRequestUrl) {
        super();
        this.clientId = clientId;
        this.authority = authority;
        this.validateAuthority = validateAuthority;
        this.redirectUri = redirectUri;
        this.postLogoutRedirectUri = postLogoutRedirectUri;
        this.navigateToLoginRequestUrl = navigateToLoginRequestUrl;
    }
}

export function AuthOptions$reflection() {
    return record_type("Configuration.AuthOptions", [], AuthOptions, () => [["clientId", string_type], ["authority", option_type(string_type)], ["validateAuthority", option_type(bool_type)], ["redirectUri", option_type(string_type)], ["postLogoutRedirectUri", option_type(string_type)], ["navigateToLoginRequestUrl", bool_type]]);
}

export class Configuration extends Record {
    constructor(auth, cache, system, framework) {
        super();
        this.auth = auth;
        this.cache = cache;
        this.system = system;
        this.framework = framework;
    }
}

export function Configuration$reflection() {
    return record_type("Configuration.Configuration", [], Configuration, () => [["auth", AuthOptions$reflection()], ["cache", option_type(class_type("Configuration.CacheOptions"))], ["system", option_type(class_type("Configuration.SystemOptions"))], ["framework", option_type(class_type("Configuration.FrameworkOptions"))]]);
}

