import { Union, Record } from "../.fable/fable-library.3.1.11/Types.js";
import { CSSProp$reflection } from "../.fable/Fable.React.7.3.0/Fable.React.Props.fs.js";
import { union_type, lambda_type, unit_type, class_type, obj_type, record_type, list_type } from "../.fable/fable-library.3.1.11/Reflection.js";
import { choose, exactlyOne } from "../.fable/fable-library.3.1.11/Seq.js";
import { keyValueList } from "../.fable/fable-library.3.1.11/MapUtil.js";
import * as react from "react";
import { Callout as Callout_1 } from "office-ui-fabric-react";

export class Styles extends Record {
    constructor(calloutMain) {
        super();
        this.calloutMain = calloutMain;
    }
}

export function Styles$reflection() {
    return record_type("FabricUi.Callout.Styles", [], Styles, () => [["calloutMain", list_type(CSSProp$reflection())]]);
}

export class Props extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Target", "OnDismiss", "Styles"];
    }
}

export function Props$reflection() {
    return union_type("FabricUi.Callout.Props", [], Props, () => [[["Item", obj_type]], [["Item", lambda_type(class_type("Browser.Types.Event"), unit_type)]], [["Item", Styles$reflection()]]]);
}

export function Callout(props, children) {
    const customProps = exactlyOne(choose((_arg1) => {
        if (_arg1.tag === 2) {
            return {
                styles: {
                    calloutMain: keyValueList(_arg1.fields[0].calloutMain, 1),
                },
            };
        }
        else {
            return void 0;
        }
    }, props));
    const typeProps = keyValueList(choose((_arg2) => {
        if (_arg2.tag === 2) {
            return void 0;
        }
        else {
            return _arg2;
        }
    }, props), 1);
    return react.createElement(Callout_1, Object.assign({}, customProps, typeProps), ...children);
}

