import { Union, Record } from "../.fable/fable-library.3.1.11/Types.js";
import { CSSProp$reflection } from "../.fable/Fable.React.7.3.0/Fable.React.Props.fs.js";
import { union_type, string_type, lambda_type, unit_type, bool_type, array_type, class_type, record_type, list_type } from "../.fable/fable-library.3.1.11/Reflection.js";
import { Props } from "./Icon.fs.js";
import { last, isEmpty, choose } from "../.fable/fable-library.3.1.11/Seq.js";
import { keyValueList } from "../.fable/fable-library.3.1.11/MapUtil.js";
import { some } from "../.fable/fable-library.3.1.11/Option.js";
import * as react from "react";
import { CommandBar } from "office-ui-fabric-react";

export class Styles extends Record {
    constructor(root) {
        super();
        this.root = root;
    }
}

export function Styles$reflection() {
    return record_type("FabricUi.Styles", [], Styles, () => [["root", list_type(CSSProp$reflection())]]);
}

export function CommandBar_CommandBarItemProps(name, icon, className, iconOnly, key, onClick, onRender, disabled, subMenuProps) {
    return {
        iconOnly: iconOnly,
        ariaLabel: name,
        className: className,
        iconOnly: iconOnly,
        iconProps: Props(icon, name),
        key: key,
        text: name,
        title: name,
        itemType: 0,
        onClick: onClick,
        disabled: disabled,
        onRender: onRender,
        subMenuProps: subMenuProps,
    };
}

export class CommandBar_CommandBarProps extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Items", "FarItems", "OverflowItems", "ShiftOnReduce", "OnDataReduced", "OnDataGrown", "ClassName", "AriaLabel", "Styles"];
    }
}

export function CommandBar_CommandBarProps$reflection() {
    return union_type("FabricUi.CommandBar.CommandBarProps", [], CommandBar_CommandBarProps, () => [[["Item", array_type(class_type("FabricUi.CommandBar.ICommandBarItemProps"))]], [["Item", array_type(class_type("FabricUi.CommandBar.ICommandBarItemProps"))]], [["Item", array_type(class_type("FabricUi.CommandBar.ICommandBarItemProps"))]], [["Item", bool_type]], [["Item", lambda_type(class_type("FabricUi.CommandBar.ICommandBarItemProps"), unit_type)]], [["Item", lambda_type(class_type("FabricUi.CommandBar.ICommandBarItemProps"), unit_type)]], [["Item", string_type]], [["Item", string_type]], [["Item", Styles$reflection()]]]);
}

export function CommandBar_CommandBar(props) {
    let customProps;
    const filtered = choose((_arg1) => ((_arg1.tag === 8) ? some({
        styles: {
            root: keyValueList(_arg1.fields[0].root, 1),
        },
    }) : (void 0)), props);
    customProps = (isEmpty(filtered) ? {} : last(filtered));
    const typeProps = keyValueList(choose((_arg2) => {
        if (_arg2.tag === 8) {
            return void 0;
        }
        else {
            return _arg2;
        }
    }, props), 1);
    return react.createElement(CommandBar, Object.assign({}, customProps, typeProps));
}

