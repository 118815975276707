import { initUserAgentApplication as initUserAgentApplication_1 } from "./Msal.js";
import Uri from "../.fable/fable-library.3.1.11/Uri.js";
import { printf, toText } from "../.fable/fable-library.3.1.11/String.js";
import { Configuration, AuthOptions } from "./Configuration.fs.js";
import { AuthenticationParameters } from "./AuthenticationParameters.fs.js";

export const initUserAgentApplication = initUserAgentApplication_1;

export const baseURL = new Uri(window.location.href);

export const aadTenantID = "72f988bf-86f1-41af-91ab-2d7cd011db47";

export const aadInstance = "https://login.microsoftonline.com/";

export const aadAuthority = toText(printf("%s%s"))(aadInstance)(aadTenantID);

export const aadClientID = "2e112846-bc89-47f1-81d2-32eb3fcc41d3";

export const aadScopeId = "f22b7c42-3571-41a6-89f8-8500fdb6052d";

export const aadReplyUrl = (() => {
    const arg20 = baseURL.host;
    const arg10 = baseURL.scheme;
    return toText(printf("%s://%s"))(arg10)(arg20);
})();

export const authOptions = new AuthOptions(aadClientID, aadAuthority, void 0, aadReplyUrl, aadReplyUrl, true);

export const msalConfiguration = new Configuration(authOptions, void 0, void 0, void 0);

export const authParameters = new AuthenticationParameters([toText(printf("api://%s/.default"))(aadScopeId)], void 0, void 0, void 0, void 0, aadAuthority, void 0, void 0, void 0, void 0, void 0);

