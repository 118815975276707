import { fromContinuations } from "../fable-library.3.1.11/Async.js";
import { HttpResponse, ResponseContent, HttpRequest, BodyContent, HttpMethod, Header } from "./Types.fs.js";
import { tail, head, isEmpty, ofArray as ofArray_1, singleton, append, empty } from "../fable-library.3.1.11/List.js";
import { ofArray, empty as empty_1 } from "../fable-library.3.1.11/Map.js";
import { join, split, isNullOrEmpty } from "../fable-library.3.1.11/String.js";
import { choose } from "../fable-library.3.1.11/Array.js";
import { getEnumerator } from "../fable-library.3.1.11/Util.js";
import { some } from "../fable-library.3.1.11/Option.js";
import { singleton as singleton_1 } from "../fable-library.3.1.11/AsyncBuilder.js";

export function FileReader_readBlobAsText(blob) {
    return fromContinuations((tupledArg) => {
        const reader = new FileReader();
        reader.onload = ((_arg1) => {
            if (reader.readyState === 2) {
                tupledArg[0](reader.result);
            }
        });
        reader.readAsText(blob);
    });
}

export function FileReader_readFileAsText(file) {
    return fromContinuations((tupledArg) => {
        const reader = new FileReader();
        reader.onload = ((_arg1) => {
            if (reader.readyState === 2) {
                tupledArg[0](reader.result);
            }
        });
        reader.readAsText(file);
    });
}

export function FormData_append(key, value, form) {
    form.append(key, value);
    return form;
}

export function FormData_appendFile(key, file, form) {
    form.append(key, file);
    return form;
}

export function FormData_appendNamedFile(key, fileName, file, form) {
    form.append(key, file, fileName);
    return form;
}

export function FormData_appendBlob(key, blob, form) {
    form.append(key, blob);
    return form;
}

export function FormData_appendNamedBlob(key, fileName, blob, form) {
    form.append(key, blob, fileName);
    return form;
}

export function Headers_contentType(value) {
    return new Header(0, "Content-Type", value);
}

export function Headers_accept(value) {
    return new Header(0, "Accept", value);
}

export function Headers_acceptCharset(value) {
    return new Header(0, "Accept-Charset", value);
}

export function Headers_acceptEncoding(value) {
    return new Header(0, "Accept-Encoding", value);
}

export function Headers_acceptLanguage(value) {
    return new Header(0, "Accept-Language", value);
}

export function Headers_acceptDateTime(value) {
    return new Header(0, "Accept-Datetime", value);
}

export function Headers_authorization(value) {
    return new Header(0, "Authorization", value);
}

export function Headers_cacheControl(value) {
    return new Header(0, "Cache-Control", value);
}

export function Headers_connection(value) {
    return new Header(0, "Connection", value);
}

export function Headers_cookie(value) {
    return new Header(0, "Cookie", value);
}

export function Headers_contentMD5(value) {
    return new Header(0, "Content-MD5", value);
}

export function Headers_date(value) {
    return new Header(0, "Date", value);
}

export function Headers_expect(value) {
    return new Header(0, "Expect", value);
}

export function Headers_ifMatch(value) {
    return new Header(0, "If-Match", value);
}

export function Headers_ifModifiedSince(value) {
    return new Header(0, "If-Modified-Since", value);
}

export function Headers_ifNoneMatch(value) {
    return new Header(0, "If-None-Match", value);
}

export function Headers_ifRange(value) {
    return new Header(0, "If-Range", value);
}

export function Headers_IfUnmodifiedSince(value) {
    return new Header(0, "If-Unmodified-Since", value);
}

export function Headers_maxForwards(value) {
    return new Header(0, "Max-Forwards", value);
}

export function Headers_origin(value) {
    return new Header(0, "Origin", value);
}

export function Headers_pragma(value) {
    return new Header(0, "Pragma", value);
}

export function Headers_proxyAuthorization(value) {
    return new Header(0, "Proxy-Authorization", value);
}

export function Headers_range(value) {
    return new Header(0, "Range", value);
}

export function Headers_referer(value) {
    return new Header(0, "Referer", value);
}

export function Headers_userAgent(value) {
    return new Header(0, "User-Agent", value);
}

export function Headers_create(key, value) {
    return new Header(0, key, value);
}

const Http_defaultRequest = new HttpRequest("", new HttpMethod(0), empty(), false, void 0, void 0, new BodyContent(0));

const Http_emptyResponse = new HttpResponse(0, "", "", empty_1(), new ResponseContent(0, ""));

function Http_splitAt(delimiter, input) {
    if (isNullOrEmpty(input)) {
        return [input];
    }
    else {
        return split(input, [delimiter], null, 0);
    }
}

function Http_serializeMethod(_arg1) {
    switch (_arg1.tag) {
        case 1: {
            return "POST";
        }
        case 3: {
            return "PATCH";
        }
        case 2: {
            return "PUT";
        }
        case 4: {
            return "DELETE";
        }
        case 6: {
            return "OPTIONS";
        }
        case 5: {
            return "HEAD";
        }
        default: {
            return "GET";
        }
    }
}

export function Http_request(url) {
    return new HttpRequest(url, Http_defaultRequest.method, Http_defaultRequest.headers, Http_defaultRequest.withCredentials, Http_defaultRequest.overridenMimeType, Http_defaultRequest.overridenResponseType, Http_defaultRequest.content);
}

export function Http_method(httpVerb, req) {
    return new HttpRequest(req.url, httpVerb, req.headers, req.withCredentials, req.overridenMimeType, req.overridenResponseType, req.content);
}

export function Http_header(singleHeader, req) {
    return new HttpRequest(req.url, req.method, append(req.headers, singleton(singleHeader)), req.withCredentials, req.overridenMimeType, req.overridenResponseType, req.content);
}

export function Http_headers(values, req) {
    return new HttpRequest(req.url, req.method, append(req.headers, values), req.withCredentials, req.overridenMimeType, req.overridenResponseType, req.content);
}

export function Http_withCredentials(enabled, req) {
    return new HttpRequest(req.url, req.method, req.headers, enabled, req.overridenMimeType, req.overridenResponseType, req.content);
}

export function Http_overrideMimeType(value, req) {
    return new HttpRequest(req.url, req.method, req.headers, req.withCredentials, value, req.overridenResponseType, req.content);
}

export function Http_overrideResponseType(value, req) {
    return new HttpRequest(req.url, req.method, req.headers, req.withCredentials, req.overridenMimeType, value, req.content);
}

export function Http_content(bodyContent, req) {
    return new HttpRequest(req.url, req.method, req.headers, req.withCredentials, req.overridenMimeType, req.overridenResponseType, bodyContent);
}

export function Http_send(req) {
    return fromContinuations((tupledArg) => {
        const xhr = new XMLHttpRequest();
        xhr.open(Http_serializeMethod(req.method), req.url);
        xhr.onreadystatechange = (() => {
            let responseText, matchValue_1, statusCode, responseType, content, matchValue_2;
            if (xhr.readyState === 4) {
                tupledArg[0]((responseText = (matchValue_1 = xhr.responseType, (matchValue_1 === "") ? xhr.responseText : ((matchValue_1 === "text") ? xhr.responseText : "")), (statusCode = (xhr.status | 0), (responseType = xhr.responseType, (content = (matchValue_2 = xhr.responseType, (matchValue_2 === "") ? (new ResponseContent(0, xhr.responseText)) : ((matchValue_2 === "text") ? (new ResponseContent(0, xhr.responseText)) : ((matchValue_2 === "arraybuffer") ? (new ResponseContent(2, xhr.response)) : ((matchValue_2 === "blob") ? (new ResponseContent(1, xhr.response)) : (new ResponseContent(3, xhr.response)))))), new HttpResponse(statusCode, responseText, responseType, ofArray(choose((headerLine) => {
                    const matchValue_3 = ofArray_1(Http_splitAt(":", headerLine));
                    if (!isEmpty(matchValue_3)) {
                        return [head(matchValue_3).toLocaleLowerCase(), join(":", tail(matchValue_3)).trim()];
                    }
                    else {
                        return void 0;
                    }
                }, Http_splitAt("\r\n", xhr.getAllResponseHeaders()))), content))))));
            }
        });
        const enumerator = getEnumerator(req.headers);
        try {
            while (enumerator["System.Collections.IEnumerator.MoveNext"]()) {
                const forLoopVar = enumerator["System.Collections.Generic.IEnumerator`1.get_Current"]();
                xhr.setRequestHeader(forLoopVar.fields[0], forLoopVar.fields[1]);
            }
        }
        finally {
            enumerator.Dispose();
        }
        xhr.withCredentials = req.withCredentials;
        const matchValue_4 = req.overridenMimeType;
        if (matchValue_4 == null) {
        }
        else {
            xhr.overrideMimeType(matchValue_4);
        }
        const matchValue_5 = req.overridenResponseType;
        if (matchValue_5 == null) {
        }
        else if (matchValue_5.tag === 1) {
            xhr.responseType = "blob";
        }
        else if (matchValue_5.tag === 2) {
            xhr.responseType = "arraybuffer";
        }
        else {
            xhr.responseType = "text";
        }
        const matchValue_6 = [req.method, req.content];
        if (matchValue_6[0].tag === 0) {
            xhr.send(some(void 0));
        }
        else if (matchValue_6[1].tag === 1) {
            xhr.send(some(matchValue_6[1].fields[0]));
        }
        else if (matchValue_6[1].tag === 3) {
            xhr.send(some(matchValue_6[1].fields[0]));
        }
        else if (matchValue_6[1].tag === 2) {
            xhr.send(some(matchValue_6[1].fields[0]));
        }
        else {
            xhr.send(some(void 0));
        }
    });
}

export function Http_get(url) {
    return singleton_1.Delay(() => singleton_1.Bind(Http_send(Http_method(new HttpMethod(0), Http_request(url))), (_arg1) => {
        const response = _arg1;
        return singleton_1.Return([response.statusCode, response.responseText]);
    }));
}

export function Http_put(url, data) {
    return singleton_1.Delay(() => singleton_1.Bind(Http_send(Http_content(new BodyContent(1, data), Http_method(new HttpMethod(2), Http_request(url)))), (_arg1) => {
        const response = _arg1;
        return singleton_1.Return([response.statusCode, response.responseText]);
    }));
}

export function Http_delete(url) {
    return singleton_1.Delay(() => singleton_1.Bind(Http_send(Http_method(new HttpMethod(4), Http_request(url))), (_arg1) => {
        const response = _arg1;
        return singleton_1.Return([response.statusCode, response.responseText]);
    }));
}

export function Http_patch(url, data) {
    return singleton_1.Delay(() => singleton_1.Bind(Http_send(Http_content(new BodyContent(1, data), Http_method(new HttpMethod(3), Http_request(url)))), (_arg1) => {
        const response = _arg1;
        return singleton_1.Return([response.statusCode, response.responseText]);
    }));
}

export function Http_post(url, data) {
    return singleton_1.Delay(() => singleton_1.Bind(Http_send(Http_content(new BodyContent(1, data), Http_method(new HttpMethod(1), Http_request(url)))), (_arg1) => {
        const response = _arg1;
        return singleton_1.Return([response.statusCode, response.responseText]);
    }));
}

