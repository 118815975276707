import { Record, Union } from "../.fable/fable-library.3.1.11/Types.js";
import { array_type, record_type, list_type, obj_type, lambda_type, unit_type, bool_type, option_type, string_type, union_type, class_type } from "../.fable/fable-library.3.1.11/Reflection.js";
import * as react from "react";
import { Fabric as Fabric_1, Separator as Separator_1, Customizer as Customizer_1 } from "office-ui-fabric-react";
import { keyValueList } from "../.fable/fable-library.3.1.11/MapUtil.js";
import { choose, collect } from "../.fable/fable-library.3.1.11/Seq.js";
import { empty } from "../.fable/fable-library.3.1.11/List.js";
import { TextField as TextField_1 } from "office-ui-fabric-react/lib/TextField";
import { SearchBox as SearchBox_1 } from "office-ui-fabric-react/lib/SearchBox";
import { List } from "office-ui-fabric-react/lib/List";
import { FocusZone as FocusZone_1 } from "office-ui-fabric-react/lib/FocusZone";
import { Spinner as Spinner_1 } from "office-ui-fabric-react/lib/Spinner";
import { useId } from "@uifabric/react-hooks";

export class CustomizerProp extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Settings", "ScopedSettings"];
    }
}

export function CustomizerProp$reflection() {
    return union_type("FabricUi.FabricUi.CustomizerProp", [], CustomizerProp, () => [[["Item", class_type("FabricUi.FabricUi.ISettings")]], [["Item", class_type("FabricUi.FabricUi.IScopedSettings")]]]);
}

export function Customizer(props, children) {
    return react.createElement(Customizer_1, keyValueList(props, 1), ...children);
}

export class TextFieldProp extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Label", "Value", "DefaultValue", "Description", "ErrorMessage", "Borderless", "Underlined", "ClassName", "Placeholder", "Prefix", "ReadOnly", "Multiline", "AutoAdjustHeight", "OnChange", "OnBlur", "OnGetErrorMessage", "OnRenderLabel", "Extra"];
    }
}

export function TextFieldProp$reflection() {
    return union_type("FabricUi.FabricUi.TextFieldProp", [], TextFieldProp, () => [[["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", option_type(string_type)]], [["Item", bool_type]], [["Item", bool_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [], [["Item", bool_type]], [["Item", bool_type]], [["Item", lambda_type(class_type("Browser.Types.Event"), unit_type)]], [["Item", lambda_type(class_type("Browser.Types.Event"), unit_type)]], [["Item", lambda_type(string_type, string_type)]], [["Item", lambda_type(obj_type, class_type("Fable.React.ReactElement"))]], [["Item", list_type(class_type("Fable.React.Props.IHTMLProp"))]]]);
}

export function TextField(props) {
    const extraProps = keyValueList(collect((_arg1) => {
        if (_arg1.tag === 17) {
            return _arg1.fields[0];
        }
        else {
            return empty();
        }
    }, props), 1);
    const typeProps = keyValueList(choose((_arg2) => {
        if (_arg2.tag === 17) {
            return void 0;
        }
        else {
            return _arg2;
        }
    }, props), 1);
    return react.createElement(TextField_1, Object.assign({}, extraProps, typeProps));
}

export class SearchBoxProp extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Placeholder", "Value", "OnChange", "OnClear", "Styles"];
    }
}

export function SearchBoxProp$reflection() {
    return union_type("FabricUi.FabricUi.SearchBoxProp", [], SearchBoxProp, () => [[["Item", string_type]], [["Item", string_type]], [["Item", lambda_type(class_type("Browser.Types.Event"), unit_type)]], [["Item", lambda_type(unit_type, unit_type)]], [["Item", obj_type]]]);
}

export function SearchBox(props) {
    return react.createElement(SearchBox_1, keyValueList(props, 1));
}

export class Item extends Record {
    constructor(name) {
        super();
        this.name = name;
    }
}

export function Item$reflection() {
    return record_type("FabricUi.FabricUi.Item", [], Item, () => [["name", string_type]]);
}

export class ListProp$1 extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Items", "OnRenderCell"];
    }
}

export function ListProp$1$reflection(gen0) {
    return union_type("FabricUi.FabricUi.ListProp`1", [gen0], ListProp$1, () => [[["Item", array_type(gen0)]], [["Item", lambda_type(gen0, class_type("Fable.React.ReactElement"))]]]);
}

export function FabricList(props) {
    return react.createElement(List, keyValueList(props, 1));
}

export class FocusZoneProps extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Direction"];
    }
}

export function FocusZoneProps$reflection() {
    return union_type("FabricUi.FabricUi.FocusZoneProps", [], FocusZoneProps, () => [[["Item", string_type]]]);
}

export function FocusZone(props, children) {
    return react.createElement(FocusZone_1, keyValueList(props, 1), ...children);
}

export class SpinnerProps extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Label"];
    }
}

export function SpinnerProps$reflection() {
    return union_type("FabricUi.FabricUi.SpinnerProps", [], SpinnerProps, () => [[["Item", string_type]]]);
}

export function Spinner(props, children) {
    return react.createElement(Spinner_1, keyValueList(props, 1), ...children);
}

export class SeparatorProps extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Vertical", "AlignContent"];
    }
}

export function SeparatorProps$reflection() {
    return union_type("FabricUi.FabricUi.SeparatorProps", [], SeparatorProps, () => [[["Item", bool_type]], [["Item", string_type]]]);
}

export function Separator(props, children) {
    return react.createElement(Separator_1, keyValueList(props, 1), ...children);
}

export class FabricProps extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Custom"];
    }
}

export function FabricProps$reflection() {
    return union_type("FabricUi.FabricUi.FabricProps", [], FabricProps, () => [[["Item", list_type(class_type("Fable.React.Props.IHTMLProp"))]]]);
}

export const useIdHook = useId;

export function Fabric(props, children) {
    const collectedprops = keyValueList(collect((_arg1) => _arg1.fields[0], props), 1);
    return react.createElement(Fabric_1, Object.assign({}, collectedprops), ...children);
}

