import { getTheme } from "@uifabric/styling";
import { mergeStyleSets } from "@uifabric/merge-styles";
import { printf, toText } from "../.fable/fable-library.3.1.11/String.js";
import { HTMLAttr } from "../.fable/Fable.React.7.3.0/Fable.React.Props.fs.js";

export const itemCell = (() => {
    let arg10;
    const theme = getTheme();
    return mergeStyleSets({
        itemCell: {
            padding: 10,
            borderBottom: (arg10 = theme.semanticColors.bodyDivider, toText(printf("1px solid %s"))(arg10)),
            selectors: {
                ["\u0026:hover"]: {
                    background: theme.palette.neutralLight,
                },
            },
        },
    });
})();

export const placeHolder = (() => {
    const theme = getTheme();
    return mergeStyleSets({
        placeHolder: {
            color: theme.semanticColors.disabledBodyText,
        },
    });
})();

export const measurementCellDim = (() => {
    const theme = getTheme();
    return mergeStyleSets({
        measurementCellDim: {
            width: "25%",
            color: theme.semanticColors.disabledBodyText,
        },
    });
})();

export const measurementCell = (() => {
    const theme = getTheme();
    return mergeStyleSets({
        measurementCell: {
            width: "25%",
        },
    });
})();

export function dimIfNone(op) {
    if (op == null) {
        return new HTMLAttr(65, measurementCellDim.measurementCellDim);
    }
    else {
        return new HTMLAttr(65, measurementCell.measurementCell);
    }
}

export const Pad10 = (() => {
    const theme = getTheme();
    return mergeStyleSets({
        Pad10: {
            padding: 10,
        },
    });
})();

export const NewitemCell = (() => {
    let arg10;
    const theme = getTheme();
    return mergeStyleSets({
        NewitemCell: {
            padding: 1,
            borderBottom: (arg10 = theme.semanticColors.bodyDivider, toText(printf("1px solid %s"))(arg10)),
        },
    });
})();

export const pad10Top = (() => {
    const theme = getTheme();
    return mergeStyleSets({
        pad10Top: {
            ["padding-top"]: 10,
        },
    });
})();

export const margin10Right = (() => {
    const theme = getTheme();
    return mergeStyleSets({
        margin10Right: {
            ["margin-right"]: 10,
        },
    });
})();

