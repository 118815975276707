import { singleton, collect, ofArray, fold } from "./.fable/fable-library.3.1.11/List.js";
import { uncurry } from "./.fable/fable-library.3.1.11/Util.js";
import { printf, toText } from "./.fable/fable-library.3.1.11/String.js";
import { Union } from "./.fable/fable-library.3.1.11/Types.js";
import { union_type, string_type } from "./.fable/fable-library.3.1.11/Reflection.js";
import { s, oneOf, str, top, map } from "./.fable/Fable.Elmish.Browser.3.0.4/parser.fs.js";
import { Navigation_modifyUrl, Navigation_newUrl } from "./.fable/Fable.Elmish.Browser.3.0.4/navigation.fs.js";

export function combine(xs) {
    let clo1;
    return fold(uncurry(2, (clo1 = toText(printf("%s/%s")), (arg10) => {
        const clo2 = clo1(arg10);
        return (arg20) => clo2(arg20);
    })), "", xs);
}

export const hashPrefix = (() => {
    const clo1 = toText(printf("#%s"));
    return (arg10) => clo1(arg10);
})();

export class EntityRoute extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["List", "View"];
    }
}

export function EntityRoute$reflection() {
    return union_type("Route.EntityRoute", [], EntityRoute, () => [[], [["Item", string_type]]]);
}

export function EntityRoute__get_ToString(this$) {
    if (this$.tag === 1) {
        return this$.fields[0];
    }
    else {
        return "";
    }
}

export class ExportRoute extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["ExportDevices", "ExportDies", "ExportGrowth", "ExportFab", "ExportSubstrate"];
    }
}

export function ExportRoute$reflection() {
    return union_type("Route.ExportRoute", [], ExportRoute, () => [[], [], [], [], []]);
}

export function ExportRoute__get_ToString(this$) {
    switch (this$.tag) {
        case 1: {
            return "dies";
        }
        case 2: {
            return "growthWafers";
        }
        case 3: {
            return "fabChips";
        }
        case 4: {
            return "substrates";
        }
        default: {
            return "devices";
        }
    }
}

export class Route extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Home", "Logout", "Export", "Template", "SEC", "CDI", "RUN", "MOD", "Wafers", "PGWs", "GrowthWafers", "FabChips", "Dies", "Devices"];
    }
}

export function Route$reflection() {
    return union_type("Route.Route", [], Route, () => [[], [], [["Item", ExportRoute$reflection()]], [["Item", EntityRoute$reflection()]], [["Item", EntityRoute$reflection()]], [["Item", EntityRoute$reflection()]], [["Item", EntityRoute$reflection()]], [["Item", EntityRoute$reflection()]], [["Item", EntityRoute$reflection()]], [["Item", EntityRoute$reflection()]], [["Item", EntityRoute$reflection()]], [["Item", EntityRoute$reflection()]], [["Item", EntityRoute$reflection()]], [["Item", EntityRoute$reflection()]]]);
}

export const EntityRouteParser = (() => {
    const parsers = ofArray([map(new EntityRoute(0), (state) => top(state)), map((arg0) => (new EntityRoute(1, arg0)), (state_1) => str(state_1))]);
    return (state_2) => oneOf(parsers, state_2);
})();

export const ExportRouteParser = (() => {
    const parsers = ofArray([map(new ExportRoute(0), (state) => top(state)), map(new ExportRoute(0), s("devices")), map(new ExportRoute(2), s("growthWafers")), map(new ExportRoute(4), s("substrates")), map(new ExportRoute(3), s("fabChips")), map(new ExportRoute(1), s("dies"))]);
    return (state_1) => oneOf(parsers, state_1);
})();

export const parser = (() => {
    let parseBefore, parseBefore_2, parseBefore_4, parseBefore_6, parseBefore_8, parseBefore_10, parseBefore_12, parseBefore_14, parseBefore_16, parseBefore_18, parseBefore_20, parseBefore_22;
    const parsers = ofArray([map(new Route(0), (state) => top(state)), map(new Route(1), s("logout")), map((arg0) => (new Route(2, arg0)), (parseBefore = s("export"), (state_1) => collect(ExportRouteParser, parseBefore(state_1)))), map((arg0_1) => (new Route(3, arg0_1)), (parseBefore_2 = s("template"), (state_3) => collect(EntityRouteParser, parseBefore_2(state_3)))), map((arg0_2) => (new Route(4, arg0_2)), (parseBefore_4 = s("SEC"), (state_5) => collect(EntityRouteParser, parseBefore_4(state_5)))), map((arg0_3) => (new Route(5, arg0_3)), (parseBefore_6 = s("CDI"), (state_7) => collect(EntityRouteParser, parseBefore_6(state_7)))), map((arg0_4) => (new Route(6, arg0_4)), (parseBefore_8 = s("RUN"), (state_9) => collect(EntityRouteParser, parseBefore_8(state_9)))), map((arg0_5) => (new Route(7, arg0_5)), (parseBefore_10 = s("MOD"), (state_11) => collect(EntityRouteParser, parseBefore_10(state_11)))), map((arg0_6) => (new Route(13, arg0_6)), (parseBefore_12 = s("devices"), (state_13) => collect(EntityRouteParser, parseBefore_12(state_13)))), map((arg0_7) => (new Route(12, arg0_7)), (parseBefore_14 = s("dies"), (state_15) => collect(EntityRouteParser, parseBefore_14(state_15)))), map((arg0_8) => (new Route(11, arg0_8)), (parseBefore_16 = s("fabChips"), (state_17) => collect(EntityRouteParser, parseBefore_16(state_17)))), map((arg0_9) => (new Route(10, arg0_9)), (parseBefore_18 = s("growthWafers"), (state_19) => collect(EntityRouteParser, parseBefore_18(state_19)))), map((arg0_10) => (new Route(9, arg0_10)), (parseBefore_20 = s("substrates"), (state_21) => collect(EntityRouteParser, parseBefore_20(state_21)))), map((arg0_11) => (new Route(8, arg0_11)), (parseBefore_22 = s("wafers"), (state_23) => collect(EntityRouteParser, parseBefore_22(state_23))))]);
    return (state_25) => oneOf(parsers, state_25);
})();

export function toString(route) {
    return hashPrefix((route.tag === 1) ? combine(singleton("logout")) : ((route.tag === 2) ? combine(ofArray(["export", ExportRoute__get_ToString(route.fields[0])])) : ((route.tag === 3) ? combine(ofArray(["template", EntityRoute__get_ToString(route.fields[0])])) : ((route.tag === 4) ? combine(ofArray(["SEC", EntityRoute__get_ToString(route.fields[0])])) : ((route.tag === 5) ? combine(ofArray(["CDI", EntityRoute__get_ToString(route.fields[0])])) : ((route.tag === 6) ? combine(ofArray(["RUN", EntityRoute__get_ToString(route.fields[0])])) : ((route.tag === 7) ? combine(ofArray(["MOD", EntityRoute__get_ToString(route.fields[0])])) : ((route.tag === 13) ? combine(ofArray(["devices", EntityRoute__get_ToString(route.fields[0])])) : ((route.tag === 8) ? combine(ofArray(["wafers", EntityRoute__get_ToString(route.fields[0])])) : ((route.tag === 9) ? combine(ofArray(["substrates", EntityRoute__get_ToString(route.fields[0])])) : ((route.tag === 12) ? combine(ofArray(["dies", EntityRoute__get_ToString(route.fields[0])])) : ((route.tag === 10) ? combine(ofArray(["growthWafers", EntityRoute__get_ToString(route.fields[0])])) : ((route.tag === 11) ? combine(ofArray(["fabChips", EntityRoute__get_ToString(route.fields[0])])) : "/")))))))))))));
}

export function navigateTo(route) {
    return Navigation_newUrl(toString(route));
}

export function updateUlr(route) {
    return Navigation_modifyUrl(toString(route));
}

