import { Union } from "../.fable/fable-library.3.1.11/Types.js";
import { union_type, bool_type, lambda_type, unit_type, class_type, string_type } from "../.fable/fable-library.3.1.11/Reflection.js";
import * as react from "react";
import { Checkbox as Checkbox_1 } from "office-ui-fabric-react";
import { keyValueList } from "../.fable/fable-library.3.1.11/MapUtil.js";

export class Props extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["ClassName", "OnChange", "Checked", "Label"];
    }
}

export function Props$reflection() {
    return union_type("FabricUi.Checkbox.Props", [], Props, () => [[["Item", string_type]], [["Item", lambda_type(class_type("Browser.Types.MouseEvent"), unit_type)]], [["Item", bool_type]], [["Item", string_type]]]);
}

export function Checkbox(props) {
    return react.createElement(Checkbox_1, keyValueList(props, 1));
}

