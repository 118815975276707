import { singleton, empty as empty_2, map, ofSeq } from "../.fable/fable-library.3.1.11/List.js";
import { collect, filter } from "../.fable/fable-library.3.1.11/Seq.js";
import { comparePrimitives, equals } from "../.fable/fable-library.3.1.11/Util.js";
import { printf, toText } from "../.fable/fable-library.3.1.11/String.js";
import { Entry$reflection, EntryValue__ValueSimplified_Z721C83C5 } from "./DataDictionary.fs.js";
import { Record } from "../.fable/fable-library.3.1.11/Types.js";
import { record_type, list_type, tuple_type, option_type, class_type, bool_type, string_type } from "../.fable/fable-library.3.1.11/Reflection.js";
import { QueryData, FileReference$reflection } from "./Entity.fs.js";
import { fromBits } from "../.fable/fable-library.3.1.11/Long.js";
import { ofList, empty } from "../.fable/fable-library.3.1.11/Map.js";
import { union, empty as empty_1 } from "../.fable/fable-library.3.1.11/Set.js";
import { defaultArg } from "../.fable/fable-library.3.1.11/Option.js";

export function simplify(stage, prefix, entries) {
    return ofSeq(filter((tupledArg_1) => {
        if (equals(tupledArg_1[1], null)) {
            return false;
        }
        else {
            return true;
        }
    }, collect((e) => map((tupledArg) => [toText(printf("%s.%s"))(prefix)(tupledArg[0]), tupledArg[1]], EntryValue__ValueSimplified_Z721C83C5(e.Entry, stage)), map((t) => t[1], entries))));
}

export class GenealogyRowState extends Record {
    constructor(id, IsDeleted, IsValid, EntityTypeName, ParentRowId, ParentVanityName, RowId, Name, LocationId, Location, TimeStamp, UserId, VanityName, RelationIds, ChildrenIds, Template, Parameters, Characteristics, Attachments) {
        super();
        this.id = id;
        this.IsDeleted = IsDeleted;
        this.IsValid = IsValid;
        this.EntityTypeName = EntityTypeName;
        this.ParentRowId = ParentRowId;
        this.ParentVanityName = ParentVanityName;
        this.RowId = RowId;
        this.Name = Name;
        this.LocationId = LocationId;
        this.Location = Location;
        this.TimeStamp = TimeStamp;
        this.UserId = UserId;
        this.VanityName = VanityName;
        this.RelationIds = RelationIds;
        this.ChildrenIds = ChildrenIds;
        this.Template = Template;
        this.Parameters = Parameters;
        this.Characteristics = Characteristics;
        this.Attachments = Attachments;
    }
}

export function GenealogyRowState$reflection() {
    return record_type("Tordensky.Domain.Genealogy.GenealogyRowState", [], GenealogyRowState, () => [["id", string_type], ["IsDeleted", bool_type], ["IsValid", bool_type], ["EntityTypeName", string_type], ["ParentRowId", class_type("System.Guid")], ["ParentVanityName", option_type(string_type)], ["RowId", class_type("System.Guid")], ["Name", string_type], ["LocationId", class_type("System.Guid")], ["Location", string_type], ["TimeStamp", class_type("System.Int64")], ["UserId", class_type("System.Guid")], ["VanityName", option_type(string_type)], ["RelationIds", class_type("Microsoft.FSharp.Collections.FSharpSet`1", [class_type("System.Guid")])], ["ChildrenIds", class_type("Microsoft.FSharp.Collections.FSharpSet`1", [class_type("System.Guid")])], ["Template", option_type(class_type("System.Guid"))], ["Parameters", list_type(tuple_type(string_type, Entry$reflection()))], ["Characteristics", list_type(tuple_type(string_type, Entry$reflection()))], ["Attachments", class_type("Microsoft.FSharp.Collections.FSharpMap`2", [string_type, FileReference$reflection()])]]);
}

export function GenealogyRowState_TestHelper_19D396AE(id, entityTypeName, name, parentID, parentVanityName) {
    const TimeStamp = fromBits(0, 0, false);
    const Attachments = empty();
    return new GenealogyRowState(id, false, true, entityTypeName, parentID, parentVanityName, id, name, "00000000-0000-0000-0000-000000000000", "", TimeStamp, "00000000-0000-0000-0000-000000000000", name, empty_1({
        Compare: (x, y) => comparePrimitives(x, y),
    }), empty_1({
        Compare: (x_1, y_1) => comparePrimitives(x_1, y_1),
    }), void 0, empty_2(), empty_2(), Attachments);
}

export function GenealogyRowState_get_Zero() {
    let id;
    let copyOfStruct = "00000000-0000-0000-0000-000000000000";
    id = copyOfStruct;
    const TimeStamp = fromBits(0, 0, false);
    const Attachments = empty();
    return new GenealogyRowState(id, false, true, "", "00000000-0000-0000-0000-000000000000", void 0, "00000000-0000-0000-0000-000000000000", "this is a bug: write at qdw@microsoft.com", "00000000-0000-0000-0000-000000000000", "", TimeStamp, "00000000-0000-0000-0000-000000000000", void 0, empty_1({
        Compare: (x, y) => comparePrimitives(x, y),
    }), empty_1({
        Compare: (x_1, y_1) => comparePrimitives(x_1, y_1),
    }), void 0, empty_2(), empty_2(), Attachments);
}

export function GenealogyRowState__MergeData_2E3E2047(this$, data, entityTypeName, name) {
    const Parameters = defaultArg(data.parameters, empty_2());
    const Characteristics = defaultArg(data.characterizations, empty_2());
    const Attachments = defaultArg(data.attachments, empty());
    return new GenealogyRowState(data.id, false, true, entityTypeName, data.parent_id, data.ParentVanityName, data.id, name, this$.LocationId, data.location, this$.TimeStamp, data.user_id, data.vanity_id, defaultArg(data.relations, empty_1({
        Compare: (x, y) => comparePrimitives(x, y),
    })), this$.ChildrenIds, data.template, Parameters, Characteristics, Attachments);
}

export function GenealogyRowState__MergeGenealogy_62A6AE20(this$, left, right) {
    return new GenealogyRowState(left.id, left.IsDeleted, left.IsValid, left.EntityTypeName, left.ParentRowId, left.ParentVanityName, left.RowId, left.Name, left.LocationId, left.Location, left.TimeStamp, left.UserId, left.VanityName, left.RelationIds, union(left.ChildrenIds, right.ChildrenIds), left.Template, left.Parameters, left.Characteristics, left.Attachments);
}

export function GenealogyRowState__ToQueryData(this$) {
    return new QueryData(ofList(singleton([this$.Name, defaultArg(this$.VanityName, this$.RowId)])), simplify(this$.Name, "parameters", this$.Parameters), simplify(this$.Name, "characterizations", this$.Characteristics));
}

