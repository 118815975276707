import { Union, Record } from "../.fable/fable-library.3.1.11/Types.js";
import { union_type, option_type, list_type, tuple_type, obj_type, class_type, record_type, string_type } from "../.fable/fable-library.3.1.11/Reflection.js";
import { int64, object, list } from "../.fable/Thoth.Json.5.1.0/Encode.fs.js";
import { append, ofArray, map } from "../.fable/fable-library.3.1.11/List.js";
import { Entry$reflection, Entry_get_Decoder, Entry__get_Encode } from "./DataDictionary.fs.js";
import { int64 as int64_1, oneOf, map as map_1, keyValuePairs, Auto_generateDecoderCached_7848D058, tuple2, string, object as object_1, list as list_1 } from "../.fable/Thoth.Json.5.1.0/Decode.fs.js";
import { uncurry } from "../.fable/fable-library.3.1.11/Util.js";
import { add, empty, FSharpMap__Add, fold, toList, ofList } from "../.fable/fable-library.3.1.11/Map.js";
import { printf, toText } from "../.fable/fable-library.3.1.11/String.js";
import { defaultArg } from "../.fable/fable-library.3.1.11/Option.js";
import { newGuid } from "../.fable/fable-library.3.1.11/Guid.js";
import { empty as empty_1 } from "../.fable/Thoth.Json.5.1.0/Extra.fs.js";
import { ExtraCoders } from "../.fable/Thoth.Json.5.1.0/Types.fs.js";

export class FileReference extends Record {
    constructor(Uri, MimeType, FileName) {
        super();
        this.Uri = Uri;
        this.MimeType = MimeType;
        this.FileName = FileName;
    }
}

export function FileReference$reflection() {
    return record_type("Tordensky.Domain.Entity.FileReference", [], FileReference, () => [["Uri", string_type], ["MimeType", string_type], ["FileName", string_type]]);
}

export function encodeEntryList(va) {
    return list(map((p) => object([["key", p[0]], ["value", Entry__get_Encode(p[1])]]), va));
}

export const decodeEntryList = (path_2) => ((value_1) => list_1((path_1, v) => object_1((get$) => [get$.Required.Field("key", (path, value) => string(path, value)), get$.Required.Field("value", uncurry(2, Entry_get_Decoder()))], path_1, v), path_2, value_1));

export const decodeOldEntryList = (() => {
    const decoder = tuple2((path, value) => string(path, value), uncurry(2, Auto_generateDecoderCached_7848D058(void 0, void 0, {
        ResolveType: Entry$reflection,
    })));
    return (path_1) => ((value_1) => list_1(uncurry(2, decoder), path_1, value_1));
})();

export const decodeValueMap = (() => {
    let dkv;
    const decoder = Entry_get_Decoder();
    dkv = ((path) => ((value) => keyValuePairs(uncurry(2, decoder), path, value)));
    return (path_1) => ((value_1) => map_1((elements) => ofList(elements), uncurry(2, dkv), path_1, value_1));
})();

export const decodeValueMapOrEntryList = (path_1) => ((value_1) => oneOf(ofArray([decodeOldEntryList, decodeEntryList, (path) => ((value) => map_1((table) => toList(table), uncurry(2, decodeValueMap), path, value))]), path_1, value_1));

export function flattenPrefixKeys(prefix, valueMap) {
    return fold((s, k, v) => FSharpMap__Add(s, toText(printf("%s.%s"))(prefix)(k), v), empty(), valueMap);
}

function mergeWithOverwrite(m1, m2) {
    return fold((s, k, v) => add(k, v, s), m1, m2);
}

export function merge(m1, m2) {
    return mergeWithOverwrite(defaultArg(m1, empty()), defaultArg(m2, empty()));
}

export const extraCoders = (() => {
    let copyOfStruct, copyOfStruct_1;
    const extra_3 = new ExtraCoders((copyOfStruct = newGuid(), copyOfStruct), add("Microsoft.FSharp.Collections.FSharpList`1[System.Tuple`2[System.String,DataDictionary.Entry]]", [(va) => encodeEntryList(va), decodeValueMapOrEntryList], empty_1.Coders));
    return new ExtraCoders((copyOfStruct_1 = newGuid(), copyOfStruct_1), add("System.Int64", [(value_1) => int64(value_1), int64_1], extra_3.Coders));
})();

export class QueryData extends Record {
    constructor(ids, parameters, characterizations) {
        super();
        this.ids = ids;
        this.parameters = parameters;
        this.characterizations = characterizations;
    }
}

export function QueryData$reflection() {
    return record_type("Tordensky.Domain.Entity.QueryData", [], QueryData, () => [["ids", class_type("Microsoft.FSharp.Collections.FSharpMap`2", [string_type, string_type])], ["parameters", list_type(tuple_type(string_type, obj_type))], ["characterizations", list_type(tuple_type(string_type, obj_type))]]);
}

export function QueryData_Merge_15D1CDE0(q1, q2) {
    return new QueryData(mergeWithOverwrite(q1.ids, q2.ids), append(q1.parameters, q2.parameters), append(q1.characterizations, q2.characterizations));
}

export class Passport extends Record {
    constructor(VanityId, parameters) {
        super();
        this.VanityId = VanityId;
        this.parameters = parameters;
    }
}

export function Passport$reflection() {
    return record_type("Tordensky.Domain.Entity.Passport", [], Passport, () => [["VanityId", string_type], ["parameters", option_type(list_type(tuple_type(string_type, Entry$reflection())))]]);
}

export function genPassport(vanityID, parameters) {
    return new Passport(vanityID, parameters);
}

export class Data extends Record {
    constructor(location, id, user_id, vanity_id, relations, parent_id, ParentVanityName, parameters, characterizations, attachments, template) {
        super();
        this.location = location;
        this.id = id;
        this.user_id = user_id;
        this.vanity_id = vanity_id;
        this.relations = relations;
        this.parent_id = parent_id;
        this.ParentVanityName = ParentVanityName;
        this.parameters = parameters;
        this.characterizations = characterizations;
        this.attachments = attachments;
        this.template = template;
    }
}

export function Data$reflection() {
    return record_type("Tordensky.Domain.Entity.Data", [], Data, () => [["location", string_type], ["id", class_type("System.Guid")], ["user_id", class_type("System.Guid")], ["vanity_id", option_type(string_type)], ["relations", option_type(class_type("Microsoft.FSharp.Collections.FSharpSet`1", [class_type("System.Guid")]))], ["parent_id", class_type("System.Guid")], ["ParentVanityName", option_type(string_type)], ["parameters", option_type(list_type(tuple_type(string_type, Entry$reflection())))], ["characterizations", option_type(list_type(tuple_type(string_type, Entry$reflection())))], ["attachments", option_type(class_type("Microsoft.FSharp.Collections.FSharpMap`2", [string_type, FileReference$reflection()]))], ["template", option_type(class_type("System.Guid"))]]);
}

export class EntityType extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Wafer", "PGW", "GrowthWafer", "FabChip", "Die", "Device", "SEC", "RUN", "CDI", "MOD", "TBD"];
    }
}

export function EntityType$reflection() {
    return union_type("Tordensky.Domain.Entity.EntityType", [], EntityType, () => [[], [], [], [], [], [], [], [], [], [], []]);
}

