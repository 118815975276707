import DateOffset from "./.fable/fable-library.3.1.11/DateOffset.js";
import { toNumber } from "./.fable/fable-library.3.1.11/Long.js";
import { Union, Record } from "./.fable/fable-library.3.1.11/Types.js";
import { Template$reflection } from "./Shared/Template.fs.js";
import { union_type, class_type, record_type, option_type, string_type, list_type } from "./.fable/fable-library.3.1.11/Reflection.js";
import { Route, EntityRoute, navigateTo } from "./Route.fs.js";
import { Cmd_none } from "./.fable/Fable.Elmish.3.1.0/cmd.fs.js";
import { Styles, StackProps, Stack } from "./fabric/Stack.fs.js";
import { isEmpty, empty, ofArray, toArray, singleton } from "./.fable/fable-library.3.1.11/List.js";
import { SearchBoxProp, SearchBox, ListProp$1, FabricList } from "./fabric/FabricUI.fs.js";
import * as react from "react";
import { Pad10, itemCell } from "./fabric/MergeStyle.fs.js";
import { defaultArg } from "./.fable/fable-library.3.1.11/Option.js";
import { entityMetaFromType } from "./Data.fs.js";
import { printf, toText } from "./.fable/fable-library.3.1.11/String.js";
import { Props, Icon } from "./fabric/Icon.fs.js";
import { Browser_Types_Event__Event_get_Value } from "./.fable/Fable.React.7.3.0/Fable.React.Extensions.fs.js";
import { CommandBar_CommandBarProps, CommandBar_CommandBar } from "./fabric/CommandBar.fs.js";
import { Props as Props_1, ActionButton } from "./fabric/Button.fs.js";
import { CSSProp } from "./.fable/Fable.React.7.3.0/Fable.React.Props.fs.js";

export function toTime(timeStamp) {
    return DateOffset(toNumber(timeStamp) * 1000, 0);
}

export class Model extends Record {
    constructor(Templates, Filter) {
        super();
        this.Templates = Templates;
        this.Filter = Filter;
    }
}

export function Model$reflection() {
    return record_type("TemplateListPage.Model", [], Model, () => [["Templates", list_type(Template$reflection())], ["Filter", option_type(string_type)]]);
}

export class Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Filter", "NewTemplate", "ViewTemplate"];
    }
}

export function Msg$reflection() {
    return union_type("TemplateListPage.Msg", [], Msg, () => [[["Item", string_type]], [], [["Item", class_type("System.Guid")]]]);
}

export function initialModel(templates) {
    return new Model(templates, void 0);
}

export function update(msg, model) {
    switch (msg.tag) {
        case 2: {
            return [model, navigateTo(new Route(3, new EntityRoute(1, msg.fields[0])))];
        }
        case 1: {
            return [model, Cmd_none()];
        }
        default: {
            return [new Model(model.Templates, msg.fields[0]), Cmd_none()];
        }
    }
}

export function viewSamples(samples, dispatch) {
    return Stack(singleton(new StackProps(5, true)), singleton(FabricList(ofArray([new ListProp$1(0, toArray(samples)), new ListProp$1(1, (item) => {
        let arg10;
        return react.createElement("div", {
            className: itemCell.itemCell,
            onClick: (ev) => {
                dispatch(new Msg(2, item.id));
            },
        }, Stack(ofArray([new StackProps(4, "center"), new StackProps(3, "space-between"), new StackProps(1, true)]), ofArray([Stack(empty(), ofArray([react.createElement("h2", {}, defaultArg(item.vanity_id, item.id)), react.createElement("h3", {}, (arg10 = entityMetaFromType(item.entityType).name, toText(printf("Stage: %s"))(arg10)))])), Icon(Props("ChevronRight", "ChevronRight"), empty())])));
    })]))));
}

export function searchbox(model, dispatch, unitVar2) {
    return SearchBox(ofArray([new SearchBoxProp(0, "Search Templates"), new SearchBoxProp(1, defaultArg(model.Filter, "")), new SearchBoxProp(4, {
        root: {
            ["align-self"]: "center",
        },
    }), new SearchBoxProp(2, (ev) => {
        dispatch(new Msg(0, Browser_Types_Event__Event_get_Value(ev)));
    })]));
}

export function sampleCommandBar(dispatch, model) {
    return CommandBar_CommandBar(ofArray([new CommandBar_CommandBarProps(6, "SampleCommandBar"), new CommandBar_CommandBarProps(0, toArray(empty()))]));
}

export function view(model, dispatch) {
    if (isEmpty(model.Templates)) {
        return Stack(singleton(new StackProps(5, true)), singleton(Stack(ofArray([new StackProps(5, true), new StackProps(3, "center"), new StackProps(4, "center")]), ofArray([react.createElement("h1", {}, "⏳"), react.createElement("h1", {}, "no templates yet "), Stack(singleton(new StackProps(1, true)), ofArray([react.createElement("p", {}, "To create a new template click on the plus button"), ActionButton(ofArray([new Props_1(2, "New"), new Props_1(4, true), new Props_1(9, {
            iconName: "Add",
        }), new Props_1(1, (e) => {
            dispatch(new Msg(1));
        })]))])), Stack(singleton(new StackProps(1, true)), ofArray([react.createElement("p", {}, "And then this"), ActionButton(ofArray([new Props_1(2, "Template"), new Props_1(4, true), new Props_1(9, {
            iconName: "FileTemplate",
        }), new Props_1(1, (e_1) => {
            dispatch(new Msg(1));
        })]))]))]))));
    }
    else {
        return Stack(ofArray([new StackProps(5, true), new StackProps(13, new Styles(ofArray([new CSSProp(272, "auto"), new CSSProp(189, "100%")])))]), ofArray([react.createElement("div", {
            className: Pad10.Pad10,
        }, react.createElement("h1", {}, "Templates")), sampleCommandBar(dispatch, model), viewSamples(model.Templates, dispatch)]));
    }
}

