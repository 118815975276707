import { toString, Record } from "./.fable/fable-library.3.1.11/Types.js";
import { list_type, tuple_type, class_type, record_type, option_type, lambda_type, string_type } from "./.fable/fable-library.3.1.11/Reflection.js";
import { Route, Route$reflection, EntityRoute$reflection } from "./Route.fs.js";
import { Data$reflection, extraCoders, Data, FileReference$reflection, EntityType, EntityType$reflection } from "./Shared/Entity.fs.js";
import { FSharpResult$2 } from "./.fable/fable-library.3.1.11/Choice.js";
import { Entry$reflection } from "./Shared/DataDictionary.fs.js";
import { singleton, contains, ofArray, empty as empty_1, map, cons, tryFind } from "./.fable/fable-library.3.1.11/List.js";
import { utcNow } from "./.fable/fable-library.3.1.11/DateOffset.js";
import { fromNumber } from "./.fable/fable-library.3.1.11/Long.js";
import { newGuid } from "./.fable/fable-library.3.1.11/Guid.js";
import { comparePrimitives, uncurry, stringHash } from "./.fable/fable-library.3.1.11/Util.js";
import { authParameters } from "./Fable.Msal/Msal.fs.js";
import { join, printf, toText } from "./.fable/fable-library.3.1.11/String.js";
import { fetch$, Types_RequestProperties$reflection, Types_RequestProperties, Types_HttpRequestHeaders } from "./.fable/Fable.Fetch.2.1.0/Fetch.fs.js";
import { keyValueList } from "./.fable/fable-library.3.1.11/MapUtil.js";
import { apiURL, entityF } from "./URL.fs.js";
import { Cmd_OfPromise_either } from "./.fable/Fable.Elmish.3.1.0/cmd.fs.js";
import { Fetch_get_5760677E } from "./.fable/Thoth.Fetch.2.0.0/Fetch.fs.js";
import { GenealogyRowState$reflection } from "./Shared/GenealogyRowState.fs.js";
import { map as map_1 } from "./.fable/fable-library.3.1.11/Set.js";
import { some } from "./.fable/fable-library.3.1.11/Option.js";
import { Auto_toString_5A41365E } from "./.fable/Thoth.Json.5.1.0/Encode.fs.js";
import { Template$reflection } from "./Shared/Template.fs.js";

export class EntityMeta extends Record {
    constructor(name, route, children, url) {
        super();
        this.name = name;
        this.route = route;
        this.children = children;
        this.url = url;
    }
}

export function EntityMeta$reflection() {
    return record_type("DData.EntityMeta", [], EntityMeta, () => [["name", string_type], ["route", lambda_type(EntityRoute$reflection(), Route$reflection())], ["children", option_type(EntityType$reflection())], ["url", string_type]]);
}

export function entiyTypeFromName(s) {
    switch (s) {
        case "growth wafer": {
            return new EntityType(2);
        }
        case "SEM": {
            return new EntityType(10);
        }
        case "AFM": {
            return new EntityType(10);
        }
        case "wafer": {
            return new EntityType(0);
        }
        case "pre growth wafer": {
            return new EntityType(1);
        }
        case "fab chip": {
            return new EntityType(3);
        }
        case "die": {
            return new EntityType(4);
        }
        case "device": {
            return new EntityType(5);
        }
        case "run": {
            return new EntityType(7);
        }
        case "SEC": {
            return new EntityType(6);
        }
        case "MOD": {
            return new EntityType(10);
        }
        case "CDI": {
            return new EntityType(8);
        }
        case "CDI_Measurement": {
            return new EntityType(10);
        }
        case "CDI_Analysis": {
            return new EntityType(10);
        }
        default: {
            return new EntityType(10);
        }
    }
}

export function entiyTypeFromString(s) {
    switch (s) {
        case "Device": {
            return new FSharpResult$2(0, new EntityType(5));
        }
        case "SEC": {
            return new FSharpResult$2(0, new EntityType(6));
        }
        case "CDI": {
            return new FSharpResult$2(0, new EntityType(8));
        }
        case "run": {
            return new FSharpResult$2(0, new EntityType(7));
        }
        case "Modeling": {
            return new FSharpResult$2(0, new EntityType(9));
        }
        case "Die": {
            return new FSharpResult$2(0, new EntityType(4));
        }
        case "Growth": {
            return new FSharpResult$2(0, new EntityType(2));
        }
        case "Substrate": {
            return new FSharpResult$2(0, new EntityType(1));
        }
        case "Wafer": {
            return new FSharpResult$2(0, new EntityType(0));
        }
        case "Device Fab": {
            return new FSharpResult$2(0, new EntityType(3));
        }
        default: {
            return new FSharpResult$2(1, "Unknown string entity type");
        }
    }
}

export function entityMetaFromType(entityType) {
    switch (entityType.tag) {
        case 9: {
            return new EntityMeta("Modeling", (arg0) => (new Route(7, arg0)), void 0, "/MOD");
        }
        case 8: {
            return new EntityMeta("CDI", (arg0_1) => (new Route(5, arg0_1)), void 0, "/CDI");
        }
        case 7: {
            return new EntityMeta("run", (arg0_2) => (new Route(6, arg0_2)), void 0, "/runs");
        }
        case 6: {
            return new EntityMeta("SEC", (arg0_3) => (new Route(4, arg0_3)), void 0, "/SEC");
        }
        case 5: {
            return new EntityMeta("Device", (arg0_4) => (new Route(13, arg0_4)), new EntityType(6), "/devices");
        }
        case 4: {
            return new EntityMeta("Die", (arg0_5) => (new Route(12, arg0_5)), new EntityType(5), "/Dies");
        }
        case 3: {
            return new EntityMeta("Device Fab", (arg0_6) => (new Route(11, arg0_6)), new EntityType(4), "/FabChips");
        }
        case 2: {
            return new EntityMeta("Growth", (arg0_7) => (new Route(10, arg0_7)), new EntityType(3), "/GrowthWafers");
        }
        case 1: {
            return new EntityMeta("Substrate", (arg0_8) => (new Route(9, arg0_8)), new EntityType(2), "/PreGrowthWafers");
        }
        case 0: {
            return new EntityMeta("Wafer", (arg0_9) => (new Route(8, arg0_9)), new EntityType(1), "/Wafers");
        }
        default: {
            return new EntityMeta("TBD", (_arg1) => (new Route(0)), void 0, "TBD");
        }
    }
}

export class EntityData extends Record {
    constructor(location, id, userid, entityType, children_id, timestamp, vanity_id, relations, parent_id, parameters, characterizations, attachments, template) {
        super();
        this.location = location;
        this.id = id;
        this.userid = userid;
        this.entityType = entityType;
        this.children_id = children_id;
        this.timestamp = timestamp;
        this.vanity_id = vanity_id;
        this.relations = relations;
        this.parent_id = parent_id;
        this.parameters = parameters;
        this.characterizations = characterizations;
        this.attachments = attachments;
        this.template = template;
    }
}

export function EntityData$reflection() {
    return record_type("DData.EntityData", [], EntityData, () => [["location", string_type], ["id", class_type("System.Guid")], ["userid", class_type("System.Guid")], ["entityType", EntityType$reflection()], ["children_id", option_type(class_type("Microsoft.FSharp.Collections.FSharpSet`1", [class_type("System.Guid")]))], ["timestamp", class_type("System.Int64")], ["vanity_id", option_type(string_type)], ["relations", option_type(class_type("Microsoft.FSharp.Collections.FSharpSet`1", [class_type("System.Guid")]))], ["parent_id", class_type("System.Guid")], ["parameters", list_type(tuple_type(string_type, Entry$reflection()))], ["characterizations", list_type(tuple_type(string_type, Entry$reflection()))], ["attachments", option_type(class_type("Microsoft.FSharp.Collections.FSharpMap`2", [string_type, FileReference$reflection()]))], ["template", option_type(class_type("System.Guid"))]]);
}

export function upsert(entry, data) {
    const matchValue = tryFind((i) => (i.id === entry.id), data);
    if (matchValue == null) {
        return cons(entry, data);
    }
    else {
        return map((i_1) => ((i_1.id === entry.id) ? entry : i_1), data);
    }
}

export function toData(entity) {
    return new Data(entity.location, entity.id, entity.userid, entity.vanity_id, entity.relations, entity.parent_id, void 0, entity.parameters, entity.characterizations, void 0, entity.template);
}

export function fromEntity(entityType, entity) {
    return new EntityData(entity.Location, entity.RowId, entity.UserId, entityType, entity.ChildrenIds, entity.TimeStamp, entity.VanityName, entity.RelationIds, entity.ParentRowId, entity.Parameters, entity.Characteristics, void 0, entity.Template);
}

export function empty(entityType, user_id, parent_id) {
    let timestamp;
    let copyOfStruct = utcNow();
    timestamp = fromNumber(copyOfStruct.getTime() / 1000);
    return new EntityData("DK", newGuid(), user_id, entityType, void 0, timestamp, void 0, void 0, parent_id, empty_1(), empty_1(), void 0, void 0);
}

export const InteractiveErrorCodes = ofArray(["consent_required", "interaction_required", "login_required"]);

export function requireInteraction(session, e) {
    try {
        if (contains(e.errorCode, InteractiveErrorCodes, {
            Equals: (x, y) => (x === y),
            GetHashCode: (x) => stringHash(x),
        })) {
            console.debug("interaction required!");
            return session.acquireTokenPopup(authParameters);
        }
        else {
            throw e;
        }
    }
    catch (matchValue) {
        throw e;
    }
}

export function acquireToken(session) {
    let pr_2;
    let pr_1;
    const pr = session.acquireTokenSilent(authParameters);
    pr_1 = (pr.then(((authResp) => {
        console.debug("mkSilent");
        console.debug(authResp.accessToken);
        return authResp;
    })));
    pr_2 = (pr_1.then(void 0, ((e) => requireInteraction(session, e))));
    return pr_2.then(((authResp_1) => {
        console.debug("final token");
        console.debug(toString(authResp_1));
        return authResp_1.accessToken;
    }));
}

export function bindToken(session, p) {
    const pr = acquireToken(session);
    return pr.then(p);
}

export function authHeader(token) {
    let headers;
    return singleton((headers = singleton(new Types_HttpRequestHeaders(5, toText(printf("Bearer %s"))(token))), new Types_RequestProperties(1, keyValueList(headers, 0))));
}

export function GetOne(session, entityType, id, msg) {
    const url = toText(entityF(entityMetaFromType(entityType).url))(id);
    return Cmd_OfPromise_either((session_1) => bindToken(session_1, (token) => Fetch_get_5760677E(apiURL(url), authHeader(token), void 0, void 0, void 0, extraCoders, uncurry(2, void 0), {
        ResolveType: GenealogyRowState$reflection,
    }, {
        ResolveType: () => list_type(Types_RequestProperties$reflection()),
    })), session, (arg_1) => msg(new FSharpResult$2(0, fromEntity(entityType, arg_1))), (arg_2) => msg(new FSharpResult$2(1, arg_2)));
}

export function Get(session, entityType, msg) {
    const meta = entityMetaFromType(entityType);
    return Cmd_OfPromise_either((session_1) => bindToken(session_1, (token) => Fetch_get_5760677E(apiURL(meta.url), authHeader(token), void 0, void 0, void 0, extraCoders, uncurry(2, void 0), {
        ResolveType: () => list_type(GenealogyRowState$reflection()),
    }, {
        ResolveType: () => list_type(Types_RequestProperties$reflection()),
    })), session, (arg_1) => msg(new FSharpResult$2(0, map((entity) => fromEntity(entityType, entity), arg_1))), (arg_2) => msg(new FSharpResult$2(1, arg_2)));
}

export function queryString(kind, ids) {
    const arg20 = join("\u0026", map_1((i) => {
        let arg10;
        let copyOfStruct = i;
        arg10 = copyOfStruct;
        return toText(printf("ids=%s"))(arg10);
    }, ids, {
        Compare: (x, y) => comparePrimitives(x, y),
    }));
    return toText(printf("/query/%s?%s"))(kind)(arg20);
}

export function GetQuery(kind, session, msg, ids) {
    return Cmd_OfPromise_either((session_1) => bindToken(session_1, (token) => {
        let headers;
        const f = fetch$(apiURL(queryString(kind, ids)), ofArray([new Types_RequestProperties(0, "GET"), (headers = singleton(new Types_HttpRequestHeaders(5, toText(printf("Bearer %s"))(token))), new Types_RequestProperties(1, keyValueList(headers, 0)))]));
        return f.then(((prom) => {
            console.log(some(prom.url));
            return prom.text();
        }));
    }), session, (arg) => msg(new FSharpResult$2(0, arg)), (arg_1) => msg(new FSharpResult$2(1, arg_1)));
}

export function Post(session, entity, msg) {
    const meta = entityMetaFromType(entity.entityType);
    return Cmd_OfPromise_either((d_1) => bindToken(session, (token) => {
        let headers;
        return fetch$(apiURL(meta.url), ofArray([new Types_RequestProperties(0, "POST"), (headers = ofArray([new Types_HttpRequestHeaders(11, "application/json"), new Types_HttpRequestHeaders(5, toText(printf("Bearer %s"))(token))]), new Types_RequestProperties(1, keyValueList(headers, 0))), new Types_RequestProperties(2, d_1)]));
    }), Auto_toString_5A41365E(0, toData(entity), void 0, extraCoders, void 0, {
        ResolveType: Data$reflection,
    }), (arg_1) => msg(new FSharpResult$2(0, arg_1)), (arg_2) => msg(new FSharpResult$2(1, arg_2)));
}

export function PostTemplate(session, template, msg) {
    return Cmd_OfPromise_either((d_1) => bindToken(session, (token) => {
        let headers;
        return fetch$(apiURL("/Template"), ofArray([new Types_RequestProperties(0, "POST"), (headers = ofArray([new Types_HttpRequestHeaders(11, "application/json"), new Types_HttpRequestHeaders(5, toText(printf("Bearer %s"))(token))]), new Types_RequestProperties(1, keyValueList(headers, 0))), new Types_RequestProperties(2, d_1)]));
    }), Auto_toString_5A41365E(0, template, void 0, extraCoders, void 0, {
        ResolveType: Template$reflection,
    }), (arg) => msg(new FSharpResult$2(0, arg)), (arg_1) => msg(new FSharpResult$2(1, arg_1)));
}

export function GetTemplates(session, msg) {
    return Cmd_OfPromise_either((session_1) => bindToken(session_1, (token) => Fetch_get_5760677E(apiURL("/Template"), authHeader(token), void 0, void 0, void 0, extraCoders, uncurry(2, void 0), {
        ResolveType: () => list_type(Template$reflection()),
    }, {
        ResolveType: () => list_type(Types_RequestProperties$reflection()),
    })), session, (arg) => msg(new FSharpResult$2(0, arg)), (arg_1) => msg(new FSharpResult$2(1, arg_1)));
}

