import * as react from "react";
import { Icon as Icon_1 } from "office-ui-fabric-react/lib/Icon";

export function Props(name, label) {
    const x = {};
    x.iconName = name;
    x.ariaLabel = label;
    return x;
}

export function Icon(props, children) {
    return react.createElement(Icon_1, props, ...children);
}

