import { Union, Record } from "../.fable/fable-library.3.1.11/Types.js";
import { CSSProp$reflection } from "../.fable/Fable.React.7.3.0/Fable.React.Props.fs.js";
import { union_type, lambda_type, unit_type, class_type, bool_type, option_type, array_type, string_type, record_type, list_type } from "../.fable/fable-library.3.1.11/Reflection.js";
import { choose, exactlyOne } from "../.fable/fable-library.3.1.11/Seq.js";
import { keyValueList } from "../.fable/fable-library.3.1.11/MapUtil.js";
import * as react from "react";
import { Nav as Nav_1 } from "office-ui-fabric-react";

export class Styles extends Record {
    constructor(root, chevronButton) {
        super();
        this.root = root;
        this.chevronButton = chevronButton;
    }
}

export function Styles$reflection() {
    return record_type("FabricUi.Nav.Styles", [], Styles, () => [["root", list_type(CSSProp$reflection())], ["chevronButton", list_type(CSSProp$reflection())]]);
}

export class NavLink extends Record {
    constructor(name, url, key, links, ariaLabel, title, disabled) {
        super();
        this.name = name;
        this.url = url;
        this.key = key;
        this.links = links;
        this.ariaLabel = ariaLabel;
        this.title = title;
        this.disabled = disabled;
    }
}

export function NavLink$reflection() {
    return record_type("FabricUi.Nav.NavLink", [], NavLink, () => [["name", string_type], ["url", string_type], ["key", string_type], ["links", option_type(array_type(NavLink$reflection()))], ["ariaLabel", option_type(string_type)], ["title", option_type(string_type)], ["disabled", bool_type]]);
}

export class NavLinkGroup extends Record {
    constructor(name, links, collapseByDefault, onLinkClick) {
        super();
        this.name = name;
        this.links = links;
        this.collapseByDefault = collapseByDefault;
        this.onLinkClick = onLinkClick;
    }
}

export function NavLinkGroup$reflection() {
    return record_type("FabricUi.Nav.NavLinkGroup", [], NavLinkGroup, () => [["name", option_type(string_type)], ["links", option_type(array_type(NavLink$reflection()))], ["collapseByDefault", bool_type], ["onLinkClick", option_type(lambda_type(class_type("Browser.Types.Event"), lambda_type(NavLink$reflection(), unit_type)))]]);
}

export class NavProps extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["OnLinkClick", "Styles", "ClassName", "Groups", "IsOnTop", "InitialSelectedKey", "SelectedKey", "AriaLabel", "ExpandButtonAriaLabel", "SelectedAriaLabel"];
    }
}

export function NavProps$reflection() {
    return union_type("FabricUi.Nav.NavProps", [], NavProps, () => [[["Item", option_type(lambda_type(class_type("Browser.Types.Event"), lambda_type(NavLink$reflection(), unit_type)))]], [["Item", Styles$reflection()]], [["Item", string_type]], [["Item", array_type(NavLinkGroup$reflection())]], [["Item", bool_type]], [["Item", option_type(string_type)]], [["Item", option_type(string_type)]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]]]);
}

export function Nav(props) {
    const customProps = exactlyOne(choose((_arg1) => {
        if (_arg1.tag === 1) {
            const p = _arg1.fields[0];
            return {
                styles: {
                    chevronButton: keyValueList(p.chevronButton, 1),
                    root: keyValueList(p.root, 1),
                },
            };
        }
        else {
            return void 0;
        }
    }, props));
    const typeProps = keyValueList(choose((_arg2) => {
        if (_arg2.tag === 1) {
            return void 0;
        }
        else {
            return _arg2;
        }
    }, props), 1);
    const props_1 = Object.assign({}, customProps, typeProps);
    return react.createElement(Nav_1, Object.assign({}, props_1));
}

