import { Styles, StackProps, Stack } from "./fabric/Stack.fs.js";
import { CSSProp } from "./.fable/Fable.React.7.3.0/Fable.React.Props.fs.js";
import { singleton, ofArray } from "./.fable/fable-library.3.1.11/List.js";
import * as react from "react";
import { Persona } from "./fabric/Persona.fs.js";

export function header(dispatch, onClick, ref, user) {
    return Stack(ofArray([new StackProps(1, true), new StackProps(0, "header"), new StackProps(3, "space-between"), new StackProps(4, "center"), new StackProps(13, new Styles(ofArray([new CSSProp(395, "100%"), new CSSProp(17, "white"), new CSSProp(218, "0 auto"), new CSSProp(189, "48px"), new CSSProp(33, "1px solid #eee")])))]), ofArray([react.createElement("h2", {
        style: {
            padding: "10px",
        },
    }, "Deprecation Notice! This website will soon be deprecated. Please refer to the Data Announcement channel in Teams for more details."), Stack(ofArray([new StackProps(1, true), new StackProps(0, "coin"), new StackProps(3, "center"), new StackProps(4, "center"), new StackProps(13, new Styles(ofArray([new CSSProp(395, "48px"), new CSSProp(189, "48px")])))]), singleton(react.createElement("div", {
        className: "div_button",
        ref: ref,
        onClick: (_arg1) => {
            dispatch(onClick);
        },
    }, Persona({
        hidePersonaDetails: true,
        size: 11,
        text: user.userName,
    }))))]));
}

