import { Record } from "../.fable/fable-library.3.1.11/Types.js";
import { record_type, list_type, tuple_type, option_type, string_type, int32_type, class_type } from "../.fable/fable-library.3.1.11/Reflection.js";
import { EntityType$reflection } from "./Entity.fs.js";
import { Entry$reflection } from "./DataDictionary.fs.js";
import { newGuid } from "../.fable/fable-library.3.1.11/Guid.js";
import { map, cons, tryFind, empty } from "../.fable/fable-library.3.1.11/List.js";

export class Template extends Record {
    constructor(id, version, user_id, vanity_id, entityType, parameters, characterizations) {
        super();
        this.id = id;
        this.version = (version | 0);
        this.user_id = user_id;
        this.vanity_id = vanity_id;
        this.entityType = entityType;
        this.parameters = parameters;
        this.characterizations = characterizations;
    }
}

export function Template$reflection() {
    return record_type("Tordensky.Domain.Template.Template", [], Template, () => [["id", class_type("System.Guid")], ["version", int32_type], ["user_id", class_type("System.Guid")], ["vanity_id", option_type(string_type)], ["entityType", EntityType$reflection()], ["parameters", list_type(tuple_type(string_type, Entry$reflection()))], ["characterizations", list_type(tuple_type(string_type, Entry$reflection()))]]);
}

export function Template_zero(entityType, user_id) {
    return new Template(newGuid(), 0, user_id, void 0, entityType, empty(), empty());
}

export function upsert(entry, data) {
    const matchValue = tryFind((i) => (i.id === entry.id), data);
    if (matchValue == null) {
        return cons(entry, data);
    }
    else {
        return map((i_1) => ((i_1.id === entry.id) ? entry : i_1), data);
    }
}

