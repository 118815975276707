import { Props, Modal } from "./fabric/Modal.fs.js";
import { empty as empty_1, append as append_1, singleton as singleton_1, tail as tail_1, map, head as head_1, isEmpty, cons, ofArray } from "./.fable/fable-library.3.1.11/List.js";
import * as react from "react";
import { Styles as Styles_1, StackItemProps, StackItem, StackProps, Stack } from "./fabric/Stack.fs.js";
import { ActionButton, IconButton, Props as Props_1, DefaultButton } from "./fabric/Button.fs.js";
import { empty, singleton, append, delay, toList } from "./.fable/fable-library.3.1.11/Seq.js";
import { useIdHook, TextFieldProp, TextField } from "./fabric/FabricUI.fs.js";
import { Browser_Types_Event__Event_get_Value } from "./.fable/Fable.React.7.3.0/Fable.React.Extensions.fs.js";
import { CSSProp, DOMAttr, HTMLAttr } from "./.fable/Fable.React.7.3.0/Fable.React.Props.fs.js";
import { map as map_1, defaultArg } from "./.fable/fable-library.3.1.11/Option.js";
import { Value__get_AsString, Value__get_IsSome } from "./Shared/DataDictionary.fs.js";
import { Label } from "./fabric/Base.fs.js";
import { Props as Props_2 } from "./fabric/Icon.fs.js";
import { FunctionComponent_Of_Z5A158BBF } from "./.fable/Fable.React.7.3.0/Fable.React.FunctionComponent.fs.js";
import { Styles, Props as Props_3, Callout } from "./fabric/Callout.fs.js";
import { printf, toText } from "./.fable/fable-library.3.1.11/String.js";
import { uncurry } from "./.fable/fable-library.3.1.11/Util.js";

export function errorModal(msg, wrapped, dispatch, reloadMsg, ignoreMsg) {
    return cons(Modal(ofArray([new Props(0, true), new Props(1, true)]), [react.createElement("div", {
        style: {
            margin: "100px",
        },
    }, react.createElement("h1", {}, "🥶 An unexpected error occurred! "), react.createElement("h2", {}, "Sorry  ! The developers have been alerted"), react.createElement("p", {}, "If you are one curious 🐱, this is what happened: "), react.createElement("p", {
        style: {
            fontFamily: "monospace",
            padding: "10px",
            background: "whitesmoke",
        },
    }, msg), react.createElement("p", {}, "Unfortunately data may have not been transmitted to the server 💔, but there\u0027s nothing we can do!"), react.createElement("p", {}, "Hit the button below to refresh, or press F5. "), Stack(ofArray([new StackProps(8, 10), new StackProps(1, true), new StackProps(3, "center")]), ofArray([DefaultButton(ofArray([new Props_1(2, "Reload!"), new Props_1(10, true), new Props_1(1, (_arg1) => {
        dispatch(reloadMsg);
    })])), DefaultButton(ofArray([new Props_1(2, "Ignore"), new Props_1(10, true), new Props_1(1, (_arg2) => {
        dispatch(ignoreMsg);
    })]))])))]), wrapped);
}

export function SimpleErrorModal(msg, wrapped, dispatch, reloadMsg) {
    return cons(Modal(ofArray([new Props(0, true), new Props(1, true)]), [react.createElement("div", {
        style: {
            margin: "100px",
        },
    }, ...toList(delay(() => append(singleton(react.createElement("h1", {}, "⚠")), delay(() => append(isEmpty(msg) ? singleton(react.createElement("div", {})) : append(singleton(react.createElement("h2", {}, head_1(msg))), delay(() => map((msg_1) => react.createElement("h2", {}, msg_1), tail_1(msg)))), delay(() => append(singleton(react.createElement("p", {}, "If you think this should be supported write us ", react.createElement("a", {
        href: "mailto:qdw@microsoft.com",
    }, "an email 📧"))), delay(() => singleton(Stack(ofArray([new StackProps(8, 10), new StackProps(1, true), new StackProps(3, "center")]), singleton_1(DefaultButton(ofArray([new Props_1(2, "Back to safety!"), new Props_1(10, true), new Props_1(1, (_arg1) => {
        dispatch(reloadMsg);
    })]))))))))))))))]), wrapped);
}

export function viewUnit(ro, id, value, updateUnit, blur, dispatch) {
    return StackItem(ofArray([new StackItemProps(1, 0), new StackItemProps(2, 2)]), singleton_1((value.tag === 0) ? TextField(append_1(ofArray([new TextFieldProp(0, "Unit"), new TextFieldProp(1, value.fields[0].Unit), new TextFieldProp(13, (ev) => {
        dispatch(updateUnit([id, Browser_Types_Event__Event_get_Value(ev)]));
    }), new TextFieldProp(17, ofArray([new HTMLAttr(159, "text"), new DOMAttr(53, (ev_1) => {
        dispatch(blur);
    })]))]), ro)) : react.createElement(react.Fragment, {})));
}

export function viewValueNominalHint(ro, id, tryValue, value, updateNumber, updateString, blur, dispatch) {
    return StackItem(singleton_1(new StackItemProps(1, 2)), singleton_1((value.tag === 1) ? TextField(append_1(ofArray([new TextFieldProp(0, "Nominal Value (leave blank if not required)"), new TextFieldProp(13, (ev_2) => {
        dispatch(updateString([id, Browser_Types_Event__Event_get_Value(ev_2)]));
    }), new TextFieldProp(1, defaultArg(value.fields[0].Value, "")), new TextFieldProp(17, ofArray([new HTMLAttr(159, "text"), new DOMAttr(53, (ev_3) => {
        dispatch(blur);
    })]))]), ro)) : TextField(append_1(ofArray([new TextFieldProp(0, "Nominal Value (leave blank if not required)"), new TextFieldProp(13, (ev) => {
        dispatch(updateNumber([id, Browser_Types_Event__Event_get_Value(ev)]));
    }), new TextFieldProp(1, defaultArg(tryValue, defaultArg(map_1((i) => {
        let copyOfStruct = i;
        return copyOfStruct.toString();
    }, value.fields[0].Value), ""))), new TextFieldProp(17, ofArray([new HTMLAttr(159, "number"), new DOMAttr(53, (ev_1) => {
        dispatch(blur);
    })]))]), ro))));
}

export function viewValuePlain(ro, id, tryValue, value, updateNumber, updateString, blur, dispatch) {
    return StackItem(singleton_1(new StackItemProps(1, 2)), singleton_1((value.tag === 1) ? TextField(append_1(ofArray([new TextFieldProp(0, "Value"), new TextFieldProp(13, (ev_2) => {
        dispatch(updateString([id, Browser_Types_Event__Event_get_Value(ev_2)]));
    }), new TextFieldProp(1, defaultArg(value.fields[0].Value, "")), new TextFieldProp(17, ofArray([new HTMLAttr(159, "text"), new DOMAttr(53, (ev_3) => {
        dispatch(blur);
    })]))]), ro)) : TextField(append_1(ofArray([new TextFieldProp(0, "Value"), new TextFieldProp(13, (ev) => {
        dispatch(updateNumber([id, Browser_Types_Event__Event_get_Value(ev)]));
    }), new TextFieldProp(1, defaultArg(tryValue, defaultArg(map_1((i) => {
        let copyOfStruct = i;
        return copyOfStruct.toString();
    }, value.fields[0].Value), ""))), new TextFieldProp(17, ofArray([new HTMLAttr(159, "number"), new DOMAttr(53, (ev_1) => {
        dispatch(blur);
    })]))]), ro))));
}

export function viewValueii(ro, id, tryValue, actualValue, nominalValue, updateNumber, updateString, blur, dispatch) {
    if (actualValue.tag === 1) {
        return toList(delay(() => append(Value__get_IsSome(nominalValue) ? singleton(TextField(ofArray([new TextFieldProp(0, "Nominal value"), new TextFieldProp(13, (ev_3) => {
            dispatch(blur);
        }), new TextFieldProp(10), new TextFieldProp(1, Value__get_AsString(nominalValue)), new TextFieldProp(17, singleton_1(new HTMLAttr(159, "text")))]))) : empty(), delay(() => singleton(TextField(append_1(ofArray([new TextFieldProp(0, "Actual value"), new TextFieldProp(13, (ev_4) => {
            dispatch(updateString([id, Browser_Types_Event__Event_get_Value(ev_4)]));
        }), new TextFieldProp(1, defaultArg(actualValue.fields[0].Value, "")), new TextFieldProp(17, ofArray([new HTMLAttr(159, "text"), new HTMLAttr(55, true), new DOMAttr(53, (ev_5) => {
            dispatch(blur);
        })]))]), ro)))))));
    }
    else {
        return toList(delay(() => append(Value__get_IsSome(nominalValue) ? singleton(TextField(ofArray([new TextFieldProp(0, "Nominal value"), new TextFieldProp(13, (ev) => {
            dispatch(blur);
        }), new TextFieldProp(10), new TextFieldProp(1, Value__get_AsString(nominalValue)), new TextFieldProp(17, singleton_1(new HTMLAttr(159, "number")))]))) : empty(), delay(() => singleton(TextField(append_1(ofArray([new TextFieldProp(0, "Actual value"), new TextFieldProp(13, (ev_1) => {
            dispatch(updateNumber([id, Browser_Types_Event__Event_get_Value(ev_1)]));
        }), new TextFieldProp(1, defaultArg(tryValue, defaultArg(map_1((i) => {
            let copyOfStruct = i;
            return copyOfStruct.toString();
        }, actualValue.fields[0].Value), ""))), new TextFieldProp(17, ofArray([new HTMLAttr(159, "number"), new HTMLAttr(55, true), new DOMAttr(53, (ev_2) => {
            dispatch(blur);
        })]))]), ro)))))));
    }
}

export function viewName(ro, id, name, updateName, blur, dispatch) {
    return StackItem(singleton_1(new StackItemProps(1, 2)), singleton_1(TextField(append_1(ofArray([new TextFieldProp(0, "Name"), new TextFieldProp(1, name), new TextFieldProp(13, (ev) => {
        dispatch(updateName([id, Browser_Types_Event__Event_get_Value(ev)]));
    }), new TextFieldProp(17, ofArray([new HTMLAttr(159, "text"), new HTMLAttr(55, true), new DOMAttr(53, (ev_1) => {
        dispatch(blur);
    })]))]), ro))));
}

export function errorMsgIfEmpty(msg, s) {
    if (s === "") {
        return msg;
    }
    else {
        return "";
    }
}

export function viewInfoLabel(text, props) {
    return react.createElement(react.Fragment, {}, Stack(ofArray([new StackProps(1, true), new StackProps(4, "center")]), ofArray([Label(empty_1(), [text]), IconButton(singleton_1(new Props_1(9, Props_2("Info", "Show Info"))))])));
}

export const charMsg = ofArray(["Here you can record characteristics and PCMs that should be measured inLine or end of line:", "The value can be left empty or set to some default value for the entry", Stack(singleton_1(new StackProps(1, true)), ofArray([react.createElement("p", {}, "To create a new entry  string value click on the plus button"), ActionButton(ofArray([new Props_1(2, "string value"), new Props_1(4, true), new Props_1(9, {
    iconName: "Add",
}), new Props_1(1, (e) => {
})]))])), Stack(singleton_1(new StackProps(1, true)), ofArray([react.createElement("p", {}, "To create a new entry number with unit value click on the plus button"), ActionButton(ofArray([new Props_1(2, "number value"), new Props_1(4, true), new Props_1(9, {
    iconName: "Add",
}), new Props_1(1, (e_1) => {
})]))]))]);

export const parMsg = ofArray(["Here you can record entries that are set/prefilled for this process with their nominal value:", "Every value must have a nominal value!", Stack(singleton_1(new StackProps(1, true)), ofArray([react.createElement("p", {}, "To create a new entry  string value click on the plus button"), ActionButton(ofArray([new Props_1(2, "string value"), new Props_1(4, true), new Props_1(9, {
    iconName: "Add",
}), new Props_1(1, (e) => {
})]))])), Stack(singleton_1(new StackProps(1, true)), ofArray([react.createElement("p", {}, "To create a new entry number with unit value click on the plus button"), ActionButton(ofArray([new Props_1(2, "number value"), new Props_1(4, true), new Props_1(9, {
    iconName: "Add",
}), new Props_1(1, (e_1) => {
})]))]))]);

export const charInfo = Stack(ofArray([new StackProps(5, true), new StackProps(3, "start"), new StackProps(4, "center")]), charMsg);

export const parInfo = Stack(ofArray([new StackProps(5, true), new StackProps(3, "start"), new StackProps(4, "center")]), parMsg);

export const emptyParams = parInfo;

export const emptyChars = charInfo;

export const viewInfoLabelTA = FunctionComponent_Of_Z5A158BBF((props) => {
    const state = react.useState(false);
    const labelId = useIdHook("labela");
    return react.createElement(react.Fragment, {}, Stack(ofArray([new StackProps(1, true), new StackProps(4, "center")]), toList(delay(() => append(singleton(react.createElement("span", {}, Label(empty_1(), [props.text]))), delay(() => append(singleton(react.createElement("div", {
        id: labelId,
        style: {
            height: 28,
        },
    }, IconButton(ofArray([new Props_1(9, Props_2("Info", "Show Info")), new Props_1(1, (_arg1) => {
        state[1](((s_1) => (!s_1)));
    })])))), delay(() => ((state[0]) ? singleton(react.createElement(react.Fragment, {}, Callout(ofArray([new Props_3(0, toText(printf("#%s"))(labelId)), new Props_3(1, (_arg2) => {
        state[1](false);
    }), new Props_3(2, new Styles(singleton_1(new CSSProp(273, "10px"))))]), [Stack(ofArray([new StackProps(1, true), new StackProps(3, "start"), new StackProps(13, new Styles_1(singleton_1(new CSSProp(273, 5))))]), props.content)]))) : singleton(react.createElement(react.Fragment, {})))))))))));
}, void 0, uncurry(2, void 0), void 0, "viewInfoLabelTA", "/src/src/Views.fs", 270);

export const parameterLabel = viewInfoLabelTA({
    content: singleton_1(parInfo),
    text: "Process parameters (SOP)",
});

export const charsLabel = viewInfoLabelTA({
    content: singleton_1(charInfo),
    text: "Characterizations and PCMs",
});

