import { Union, Record } from "../.fable/fable-library.3.1.11/Types.js";
import { union_type, int32_type, array_type, record_type, option_type, lambda_type, unit_type, class_type, string_type } from "../.fable/fable-library.3.1.11/Reflection.js";
import * as react from "react";
import { Breadcrumb } from "office-ui-fabric-react";
import { keyValueList } from "../.fable/fable-library.3.1.11/MapUtil.js";

export class BreadCrumbItem extends Record {
    constructor(key, text, onClick) {
        super();
        this.key = key;
        this.text = text;
        this.onClick = onClick;
    }
}

export function BreadCrumbItem$reflection() {
    return record_type("FabricUi.BreadCrumb.BreadCrumbItem", [], BreadCrumbItem, () => [["key", string_type], ["text", string_type], ["onClick", option_type(lambda_type(class_type("Browser.Types.MouseEvent"), unit_type))]]);
}

export class Props extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Items", "MaxDisplayedItems", "AriaLabel", "OverflowAriaLabel"];
    }
}

export function Props$reflection() {
    return union_type("FabricUi.BreadCrumb.Props", [], Props, () => [[["Item", array_type(BreadCrumbItem$reflection())]], [["Item", int32_type]], [["Item", string_type]], [["Item", string_type]]]);
}

export function BreadCrumb(props) {
    return react.createElement(Breadcrumb, keyValueList(props, 1));
}

