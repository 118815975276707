import { Union } from "../.fable/fable-library.3.1.11/Types.js";
import { union_type, bool_type } from "../.fable/fable-library.3.1.11/Reflection.js";
import * as react from "react";
import { Label as Label_1 } from "office-ui-fabric-react/lib/Label";
import { keyValueList } from "../.fable/fable-library.3.1.11/MapUtil.js";

export class LabelProps extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Disabled"];
    }
}

export function LabelProps$reflection() {
    return union_type("FabricUi.Label.LabelProps", [], LabelProps, () => [[["Item", bool_type]]]);
}

export function Label(props, children) {
    return react.createElement(Label_1, keyValueList(props, 1), ...children);
}

