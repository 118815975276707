import { Auto_generateDecoderCached_7848D058, fromString, string, option, guid as guid_1, object, list as list_1 } from "./.fable/Thoth.Json.5.1.0/Decode.fs.js";
import { Cmd_ofSub } from "./.fable/Fable.Elmish.3.1.0/cmd.fs.js";
import { awaitPromise, startImmediate } from "./.fable/fable-library.3.1.11/Async.js";
import { singleton } from "./.fable/fable-library.3.1.11/AsyncBuilder.js";
import { Union, Record } from "./.fable/fable-library.3.1.11/Types.js";
import { union_type, list_type, record_type, class_type, option_type, string_type } from "./.fable/fable-library.3.1.11/Reflection.js";
import { fromEntity, acquireToken, entityMetaFromType } from "./Data.fs.js";
import { entityF, apiURL } from "./URL.fs.js";
import { printf, toText } from "./.fable/fable-library.3.1.11/String.js";
import { Headers_authorization, Http_header, Http_request, Http_method, Http_send } from "./.fable/Fable.SimpleHttp.3.0.0/Http.fs.js";
import { HttpMethod } from "./.fable/Fable.SimpleHttp.3.0.0/Types.fs.js";
import { uncurry } from "./.fable/fable-library.3.1.11/Util.js";
import { CaseStrategy } from "./.fable/Thoth.Json.5.1.0/Types.fs.js";
import { extraCoders } from "./Shared/Entity.fs.js";
import { Result_Map, FSharpResult$2 } from "./.fable/fable-library.3.1.11/Choice.js";
import { GenealogyRowState$reflection } from "./Shared/GenealogyRowState.fs.js";
import { map } from "./.fable/fable-library.3.1.11/List.js";
import { Template$reflection } from "./Shared/Template.fs.js";

export const decodeCSharpTuple = (path_4) => ((value_3) => list_1((path_3, v) => object((get$) => [get$.Required.Field("item1", (path, value) => guid_1(path, value)), get$.Required.Field("item2", (path_2, value_2) => option((path_1, value_1) => string(path_1, value_1), path_2, value_2))], path_3, v), path_4, value_3));

export function fromAsync(operation) {
    return Cmd_ofSub((dispatch) => {
        startImmediate(singleton.Delay(() => singleton.Bind(operation, (_arg1) => {
            dispatch(_arg1);
            return singleton.Zero();
        })));
    });
}

export function startAsnyc(msg, operation) {
    return Cmd_ofSub((dispatch) => {
        startImmediate(singleton.Delay(() => singleton.Bind(operation, (_arg1) => {
            dispatch(msg(_arg1));
            return singleton.Zero();
        })));
    });
}

export class LineageEntry extends Record {
    constructor(sn, id, name) {
        super();
        this.sn = sn;
        this.id = id;
        this.name = name;
    }
}

export function LineageEntry$reflection() {
    return record_type("RemoteData.LineageEntry", [], LineageEntry, () => [["sn", option_type(string_type)], ["id", class_type("System.Guid")], ["name", string_type]]);
}

export function GetLineage(session, entityType, guid) {
    const meta = entityMetaFromType(entityType);
    const url = apiURL(toText(printf("%s/%A/lineage"))(meta.url)(guid));
    return singleton.Delay(() => singleton.Bind(awaitPromise(acquireToken(session)), (_arg1) => {
        let req_1;
        return singleton.Bind(Http_send((req_1 = Http_method(new HttpMethod(0), Http_request(url)), Http_header(Headers_authorization(toText(printf("Bearer %s"))(_arg1)), req_1))), (_arg2) => {
            const response = _arg2;
            return (response.statusCode === 200) ? singleton.Return(fromString(uncurry(2, Auto_generateDecoderCached_7848D058(new CaseStrategy(0), extraCoders, {
                ResolveType: () => list_type(LineageEntry$reflection()),
            })), response.responseText)) : singleton.Return(new FSharpResult$2(1, response.responseText));
        });
    }));
}

export function GetChildren(session, entityType, guid) {
    const meta = entityMetaFromType(entityType);
    const url = apiURL(toText(printf("%s/%A/children"))(meta.url)(guid));
    return singleton.Delay(() => singleton.Bind(awaitPromise(acquireToken(session)), (_arg1) => {
        let req_1;
        return singleton.Bind(Http_send((req_1 = Http_method(new HttpMethod(0), Http_request(url)), Http_header(Headers_authorization(toText(printf("Bearer %s"))(_arg1)), req_1))), (_arg2) => {
            const response = _arg2;
            return (response.statusCode === 200) ? singleton.Return(fromString(uncurry(2, Auto_generateDecoderCached_7848D058(new CaseStrategy(0), extraCoders, {
                ResolveType: () => list_type(LineageEntry$reflection()),
            })), response.responseText)) : singleton.Return(new FSharpResult$2(1, response.responseText));
        });
    }));
}

export function GetAsync(session, entityType) {
    const meta = entityMetaFromType(entityType);
    const url = apiURL(toText(printf("%s%s/names"))(meta.url)(meta.url));
    return singleton.Delay(() => singleton.Bind(awaitPromise(acquireToken(session)), (_arg1) => {
        let req_1;
        return singleton.Bind(Http_send((req_1 = Http_method(new HttpMethod(0), Http_request(url)), Http_header(Headers_authorization(toText(printf("Bearer %s"))(_arg1)), req_1))), (_arg2) => {
            const response = _arg2;
            return (response.statusCode === 200) ? singleton.Return(fromString(uncurry(2, decodeCSharpTuple), response.responseText)) : singleton.Return(new FSharpResult$2(1, response.responseText));
        });
    }));
}

export function GetOneAsync(session, entityType, guid) {
    const meta = entityMetaFromType(entityType);
    const url = apiURL(toText(printf("%s/%A"))(meta.url)(guid));
    return singleton.Delay(() => singleton.Bind(awaitPromise(acquireToken(session)), (_arg1) => {
        let req_1;
        return singleton.Bind(Http_send((req_1 = Http_method(new HttpMethod(0), Http_request(url)), Http_header(Headers_authorization(toText(printf("Bearer %s"))(_arg1)), req_1))), (_arg2) => {
            const response = _arg2;
            return (response.statusCode === 200) ? singleton.Return(Result_Map((entity) => fromEntity(entityType, entity), fromString(uncurry(2, Auto_generateDecoderCached_7848D058(new CaseStrategy(0), extraCoders, {
                ResolveType: GenealogyRowState$reflection,
            })), response.responseText))) : singleton.Return(new FSharpResult$2(1, response.responseText));
        });
    }));
}

export function Delete(session, entityType, guid) {
    const meta = entityMetaFromType(entityType);
    const url = apiURL(toText(printf("%s/%A"))(meta.url)(guid));
    return singleton.Delay(() => singleton.Bind(awaitPromise(acquireToken(session)), (_arg1) => {
        let req_1;
        return singleton.Bind(Http_send((req_1 = Http_method(new HttpMethod(4), Http_request(url)), Http_header(Headers_authorization(toText(printf("Bearer %s"))(_arg1)), req_1))), (_arg2) => {
            const response = _arg2;
            return (response.statusCode === 200) ? singleton.Return(new FSharpResult$2(0, void 0)) : singleton.Return(new FSharpResult$2(1, response.responseText));
        });
    }));
}

export function GetManyasync(session, entityType) {
    const url = apiURL(entityMetaFromType(entityType).url);
    return singleton.Delay(() => singleton.Bind(awaitPromise(acquireToken(session)), (_arg1) => {
        let req_1;
        return singleton.Bind(Http_send((req_1 = Http_method(new HttpMethod(0), Http_request(url)), Http_header(Headers_authorization(toText(printf("Bearer %s"))(_arg1)), req_1))), (_arg2) => {
            let decoder;
            const response = _arg2;
            return (response.statusCode === 200) ? singleton.Return(Result_Map((list) => map((entity) => fromEntity(entityType, entity), list), fromString(uncurry(2, (decoder = Auto_generateDecoderCached_7848D058(new CaseStrategy(0), extraCoders, {
                ResolveType: GenealogyRowState$reflection,
            }), (path) => ((value) => list_1(uncurry(2, decoder), path, value)))), response.responseText))) : singleton.Return(new FSharpResult$2(1, response.responseText));
        });
    }));
}

export function GetTemplateAsync(session) {
    const url = apiURL("/Template");
    return singleton.Delay(() => singleton.Bind(awaitPromise(acquireToken(session)), (_arg1) => {
        let req_1;
        return singleton.Bind(Http_send((req_1 = Http_method(new HttpMethod(0), Http_request(url)), Http_header(Headers_authorization(toText(printf("Bearer %s"))(_arg1)), req_1))), (_arg2) => {
            const response = _arg2;
            return (response.statusCode === 200) ? singleton.Return(fromString(uncurry(2, Auto_generateDecoderCached_7848D058(new CaseStrategy(0), extraCoders, {
                ResolveType: () => list_type(Template$reflection()),
            })), response.responseText)) : singleton.Return(new FSharpResult$2(1, response.responseText));
        });
    }));
}

export function GetOneAsnyc(session, entityType, id, msg) {
    const url = apiURL(toText(entityF(entityMetaFromType(entityType).url))(id));
    return singleton.Delay(() => singleton.Bind(awaitPromise(acquireToken(session)), (_arg1) => {
        let req_1;
        return singleton.Bind(Http_send((req_1 = Http_method(new HttpMethod(0), Http_request(url)), Http_header(Headers_authorization(toText(printf("Bearer %s"))(_arg1)), req_1))), (_arg2) => {
            const response = _arg2;
            return (response.statusCode === 200) ? singleton.Return(Result_Map((entity) => fromEntity(entityType, entity), fromString(uncurry(2, Auto_generateDecoderCached_7848D058(new CaseStrategy(0), extraCoders, {
                ResolveType: GenealogyRowState$reflection,
            })), response.responseText))) : singleton.Return(new FSharpResult$2(1, response.responseText));
        });
    }));
}

export function AsyncMap(f, computation) {
    return singleton.Delay(() => singleton.Bind(computation, (_arg1) => singleton.Return(f(_arg1))));
}

export class Deferred$1 extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["NotStarted", "InProgress", "Resolved"];
    }
}

export function Deferred$1$reflection(gen0) {
    return union_type("RemoteData.Deferred`1", [gen0], Deferred$1, () => [[], [], [["Item", gen0]]]);
}

export function Deferred$1_defaultValue(value, this$) {
    switch (this$.tag) {
        case 1: {
            return value;
        }
        case 2: {
            return this$.fields[0];
        }
        default: {
            return value;
        }
    }
}

export class AsyncOperationEvent$1 extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Started", "Finished"];
    }
}

export function AsyncOperationEvent$1$reflection(gen0) {
    return union_type("RemoteData.AsyncOperationEvent`1", [gen0], AsyncOperationEvent$1, () => [[], [["Item", gen0]]]);
}

