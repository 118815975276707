import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { initializeIcons } from "@uifabric/icons";
import { authParameters, msalConfiguration, initUserAgentApplication } from "./Fable.Msal/Msal.fs.js";
import { ProgramModule_mkProgram, ProgramModule_run } from "./.fable/Fable.Elmish.3.1.0/program.fs.js";
import { ProgramModule_toNavigable } from "./.fable/Fable.Elmish.Browser.3.0.4/navigation.fs.js";
import { parseHash } from "./.fable/Fable.Elmish.Browser.3.0.4/parser.fs.js";
import { parser as parser_1 } from "./Route.fs.js";
import { view, update, init, urlUpdated } from "./App.fs.js";
import { Program_withReactSynchronous } from "./.fable/Fable.Elmish.React.3.0.1/react.fs.js";

export const aiConfig = {};

aiConfig.instrumentationKey = "b55dd72c-ac6b-4197-abcb-5df246d4dcfa";

aiConfig.enableAutoRouteTracking = true;

export const ai = new ApplicationInsights({config:aiConfig});

void ai.loadAppInsights();

ai.trackPageView();

(function () {
    const value = initializeIcons();
})();

export const userAgentApplication = initUserAgentApplication(msalConfiguration);

(function () {
    const matchValue = userAgentApplication.getAccount();
    if (matchValue != null) {
        const u = matchValue;
        console.debug("MSAL: user logged in");
        ProgramModule_run(ProgramModule_toNavigable((location) => parseHash(parser_1, location), (result, model_2) => urlUpdated(result, model_2), Program_withReactSynchronous("root", ProgramModule_mkProgram((route) => init(u, userAgentApplication, ai, route), (msg, model) => update(msg, model), (model_1, dispatch) => view(model_1, dispatch)))));
    }
    else {
        console.debug("MSAL: logging user in");
        userAgentApplication.loginRedirect(authParameters);
    }
})();

