import { Union, Record } from "../.fable/fable-library.3.1.11/Types.js";
import { CSSProp$reflection } from "../.fable/Fable.React.7.3.0/Fable.React.Props.fs.js";
import { union_type, lambda_type, unit_type, class_type, option_type, obj_type, bool_type, string_type, record_type, list_type } from "../.fable/fable-library.3.1.11/Reflection.js";
import { exactlyOne, last, isEmpty, choose } from "../.fable/fable-library.3.1.11/Seq.js";
import { keyValueList } from "../.fable/fable-library.3.1.11/MapUtil.js";
import { some } from "../.fable/fable-library.3.1.11/Option.js";
import * as react from "react";
import { StackItem as StackItem_1, Stack as Stack_1 } from "office-ui-fabric-react/lib/Stack";
import { isEmpty as isEmpty_1 } from "../.fable/fable-library.3.1.11/List.js";

export class Styles extends Record {
    constructor(root) {
        super();
        this.root = root;
    }
}

export function Styles$reflection() {
    return record_type("FabricUi.Stack.Styles", [], Styles, () => [["root", list_type(CSSProp$reflection())]]);
}

export class StackProps extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["ClassName", "Horizontal", "Reversed", "HorizontalAlign", "VerticalAlign", "VerticalFill", "DisableShrink", "Grow", "Gap", "MaxWidth", "MaxHeight", "Padding", "Wrap", "Styles", "OnClick"];
    }
}

export function StackProps$reflection() {
    return union_type("FabricUi.Stack.StackProps", [], StackProps, () => [[["Item", string_type]], [["Item", bool_type]], [["Item", bool_type]], [["Item", string_type]], [["Item", string_type]], [["Item", bool_type]], [["Item", bool_type]], [["Item", option_type(obj_type)]], [["Item", obj_type]], [["Item", option_type(obj_type)]], [["Item", option_type(obj_type)]], [["Item", option_type(obj_type)]], [["Item", option_type(bool_type)]], [["Item", Styles$reflection()]], [["Item", lambda_type(class_type("Browser.Types.MouseEvent"), unit_type)]]]);
}

export function Stack(props, children) {
    let customProps;
    const filtered = choose((_arg1) => ((_arg1.tag === 13) ? some({
        styles: {
            root: keyValueList(_arg1.fields[0].root, 1),
        },
    }) : (void 0)), props);
    customProps = (isEmpty(filtered) ? {} : last(filtered));
    const typeProps = keyValueList(choose((_arg2) => {
        if (_arg2.tag === 13) {
            return void 0;
        }
        else {
            return _arg2;
        }
    }, props), 1);
    return react.createElement(Stack_1, Object.assign({}, customProps, typeProps), ...children);
}

export class StackItemProps extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["ClassName", "Grow", "Shrink", "DisableShrink", "Align", "VerticalFill", "Styles", "Order"];
    }
}

export function StackItemProps$reflection() {
    return union_type("FabricUi.Stack.StackItemProps", [], StackItemProps, () => [[["Item", string_type]], [["Item", obj_type]], [["Item", obj_type]], [["Item", bool_type]], [["Item", string_type]], [["Item", bool_type]], [["Item", Styles$reflection()]], [["Item", obj_type]]]);
}

export function StackItem(props, children) {
    let customProps, collectedProps, typeProps;
    return react.createElement(StackItem_1, isEmpty_1(props) ? {} : (customProps = (collectedProps = choose((_arg1) => {
        if (_arg1.tag === 6) {
            return some({
                styles: {
                    root: keyValueList(_arg1.fields[0].root, 1),
                },
            });
        }
        else {
            return void 0;
        }
    }, props), (!isEmpty(collectedProps)) ? exactlyOne(collectedProps) : {}), (typeProps = keyValueList(choose((_arg2) => {
        if (_arg2.tag === 6) {
            return void 0;
        }
        else {
            return _arg2;
        }
    }, props), 1), Object.assign({}, customProps, typeProps))), ...children);
}

