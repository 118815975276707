import { Union } from "../.fable/fable-library.3.1.11/Types.js";
import { union_type, lambda_type, unit_type, int32_type, array_type, class_type, bool_type, option_type, string_type } from "../.fable/fable-library.3.1.11/Reflection.js";
import * as react from "react";
import { Dropdown } from "office-ui-fabric-react/lib/Dropdown";
import { keyValueList } from "../.fable/fable-library.3.1.11/MapUtil.js";

export class Props extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Placeholder", "Disabled", "SelectedKey", "Label", "Options", "OnChange"];
    }
}

export function Props$reflection() {
    return union_type("FabricUi.Dropdown.Props", [], Props, () => [[["Item", option_type(string_type)]], [["Item", bool_type]], [["Item", option_type(string_type)]], [["Item", string_type]], [["Item", array_type(class_type("FabricUi.Dropdown.ISelectableOption"))]], [["Item", option_type(lambda_type(class_type("Browser.Types.Event"), lambda_type(class_type("FabricUi.Dropdown.ISelectableOption"), lambda_type(int32_type, unit_type))))]]]);
}

export function Dropdown_Dropdown(props) {
    return react.createElement(Dropdown, keyValueList(props, 1));
}

