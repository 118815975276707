import { UserAgentApplication } from "msal";

export var initUserAgentApplication = (config) => {
    const msalInstance = new UserAgentApplication(config);
    msalInstance.handleRedirectCallback((error, response) => {
        // if error is not null, something went wrong
        // if not, response is a successful login response
        console.log("------------------------------ MSAL wrapper");
        console.error(error);
        console.log(response)
    });
    return msalInstance;
};
