import { Union } from "../.fable/fable-library.3.1.11/Types.js";
import { union_type, lambda_type, unit_type, class_type, bool_type } from "../.fable/fable-library.3.1.11/Reflection.js";
import * as react from "react";
import { Modal as Modal_1 } from "office-ui-fabric-react";
import { keyValueList } from "../.fable/fable-library.3.1.11/MapUtil.js";

export class Props extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["IsBlocking", "IsOpen", "OnDismiss"];
    }
}

export function Props$reflection() {
    return union_type("FabricUi.Modal.Props", [], Props, () => [[["Item", bool_type]], [["Item", bool_type]], [["Item", lambda_type(class_type("Browser.Types.MouseEvent"), unit_type)]]]);
}

export function Modal(props, children) {
    return react.createElement(Modal_1, keyValueList(props, 1), ...children);
}

