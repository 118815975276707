import { Union, Record } from "../.fable/fable-library.3.1.11/Types.js";
import { class_type, getUnionFields, name as name_1, tuple_type, union_type, record_type, option_type, float64_type, string_type } from "../.fable/fable-library.3.1.11/Reflection.js";
import { printf, toText } from "../.fable/fable-library.3.1.11/String.js";
import { map, defaultArg } from "../.fable/fable-library.3.1.11/Option.js";
import { option as option_1, object } from "../.fable/Thoth.Json.5.1.0/Encode.fs.js";
import { float, map as map_1, oneOf, option as option_2, string, field, object as object_1 } from "../.fable/Thoth.Json.5.1.0/Decode.fs.js";
import { map as map_2, singleton, ofArray } from "../.fable/fable-library.3.1.11/List.js";
import { append, singleton as singleton_1, delay, toList } from "../.fable/fable-library.3.1.11/Seq.js";
import { equals } from "../.fable/fable-library.3.1.11/Util.js";

export function ValidOption(a) {
    return !(a == null);
}

export function ValidString(a) {
    return a.length !== 0;
}

export class Number$ extends Record {
    constructor(Name, Value, Unit) {
        super();
        this.Name = Name;
        this.Value = Value;
        this.Unit = Unit;
    }
}

export function Number$$reflection() {
    return record_type("DataDictionary.Number", [], Number$, () => [["Name", string_type], ["Value", option_type(float64_type)], ["Unit", string_type]]);
}

export function Number__get_Filled(this$) {
    if (ValidString(this$.Name) ? ValidOption(this$.Value) : false) {
        return ValidString(this$.Unit);
    }
    else {
        return false;
    }
}

export function Number__get_Valid(this$) {
    if (ValidString(this$.Name)) {
        return ValidString(this$.Unit);
    }
    else {
        return false;
    }
}

export function Number__Simple_Z721C83C5(this$, prefix) {
    const matchValue = this$.Value;
    if (matchValue == null) {
        return [toText(printf("%s.%s (%s)"))(prefix)(this$.Name)(this$.Unit), null];
    }
    else {
        const n = matchValue;
        return [toText(printf("%s.%s (%s)"))(prefix)(this$.Name)(this$.Unit), n];
    }
}

export class String$ extends Record {
    constructor(Name, Value) {
        super();
        this.Name = Name;
        this.Value = Value;
    }
}

export function String$$reflection() {
    return record_type("DataDictionary.String", [], String$, () => [["Name", string_type], ["Value", option_type(string_type)]]);
}

export function String__get_Filled(this$) {
    if (ValidString(this$.Name)) {
        return ValidOption(this$.Value);
    }
    else {
        return false;
    }
}

export function String__get_Valid(this$) {
    return ValidString(this$.Name);
}

export function String__Simple_Z721C83C5(this$, prefix) {
    const matchValue = this$.Value;
    if (matchValue == null) {
        return [toText(printf("%s.%s"))(prefix)(this$.Name), null];
    }
    else {
        const v = matchValue;
        return [toText(printf("%s.%s"))(prefix)(this$.Name), v];
    }
}

export class Value extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Number", "String"];
    }
}

export function Value$reflection() {
    return union_type("DataDictionary.Value", [], Value, () => [[["Item", Number$$reflection()]], [["Item", String$$reflection()]]]);
}

export function Value__get_IsSome(this$) {
    if (this$.tag === 1) {
        return this$.fields[0].Value != null;
    }
    else {
        return this$.fields[0].Value != null;
    }
}

export function Value__get_AsString(this$) {
    if (this$.tag === 1) {
        return defaultArg(this$.fields[0].Value, "");
    }
    else {
        return defaultArg(map((a) => {
            let copyOfStruct = a;
            return copyOfStruct.toString();
        }, this$.fields[0].Value), "");
    }
}

export function Value__get_Name(this$) {
    if (this$.tag === 1) {
        return this$.fields[0].Name;
    }
    else {
        return this$.fields[0].Name;
    }
}

export function Value__get_Valid(this$) {
    if (this$.tag === 1) {
        return String__get_Valid(this$.fields[0]);
    }
    else {
        return Number__get_Valid(this$.fields[0]);
    }
}

export function Value__Simple_Z721C83C5(this$, prefix) {
    if (this$.tag === 1) {
        return String__Simple_Z721C83C5(this$.fields[0], prefix);
    }
    else {
        return Number__Simple_Z721C83C5(this$.fields[0], prefix);
    }
}

export function Value__get_Encode(this$) {
    if (this$.tag === 1) {
        const v_1 = this$.fields[0];
        return object([["type", "string"], ["name", v_1.Name], ["value", option_1((value_7) => value_7)(v_1.Value)]]);
    }
    else {
        const v = this$.fields[0];
        return object([["type", "number"], ["name", v.Name], ["value", option_1((value_2) => value_2)(v.Value)], ["unit", v.Unit]]);
    }
}

export function Value_Decoder(path, value) {
    return object_1((get$) => {
        const matchValue = get$.Required.Raw((path_2, value_2) => field("type", (path_1, value_1) => string(path_1, value_1), path_2, value_2));
        switch (matchValue) {
            case "string": {
                return new Value(1, new String$(get$.Required.Field("name", (path_3, value_3) => string(path_3, value_3)), get$.Required.Field("value", (path_5, value_5) => option_2((path_4, value_4) => string(path_4, value_4), path_5, value_5))));
            }
            case "number": {
                try {
                    return new Value(0, new Number$(get$.Required.Field("name", (path_6, value_6) => string(path_6, value_6)), get$.Required.Field("value", (path_12, value_12) => option_2((path_11, value_11) => oneOf(ofArray([(path_8) => ((value_8) => map_1((s) => s, (path_7, value_7) => float(path_7, value_7), path_8, value_8)), (path_10) => ((value_10) => map_1((_arg1) => Number.NaN, (path_9, value_9) => string(path_9, value_9), path_10, value_10))]), path_11, value_11), path_12, value_12)), get$.Required.Field("unit", (path_13, value_13) => string(path_13, value_13))));
                }
                catch (matchValue_1) {
                    throw matchValue_1;
                }
            }
            default: {
                const message = toText(printf("Expected type string or number but got %O"))(matchValue);
                throw (new Error(message));
            }
        }
    }, path, value);
}

export class Actual extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Actual"];
    }
}

export function Actual$reflection() {
    return union_type("DataDictionary.Actual", [], Actual, () => [[["Item", Value$reflection()]]]);
}

export function Actual__get_Value(this$) {
    return this$.fields[0];
}

export function Actual__Update(this$, updater, v) {
    return new Actual(0, updater(v, Actual__get_Value(this$)));
}

export class Nominal extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Nominal"];
    }
}

export function Nominal$reflection() {
    return union_type("DataDictionary.Nominal", [], Nominal, () => [[["Item", Value$reflection()]]]);
}

export function Nominal__get_Value(this$) {
    return this$.fields[0];
}

export function Nominal__Update(this$, updater, v) {
    return new Nominal(0, updater(v, Nominal__get_Value(this$)));
}

export class EntryValue extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Prefilled", "Measured", "Free"];
    }
}

export function EntryValue$reflection() {
    return union_type("DataDictionary.EntryValue", [], EntryValue, () => [[["Item", tuple_type(Nominal$reflection(), option_type(Actual$reflection()))]], [["Item", Actual$reflection()]], [["Item", Actual$reflection()]]]);
}

export function EntryValue__ValueSimplified_Z721C83C5(this$, prefix) {
    switch (this$.tag) {
        case 1: {
            return singleton(Value__Simple_Z721C83C5(Actual__get_Value(this$.fields[0]), prefix));
        }
        case 2: {
            return singleton(Value__Simple_Z721C83C5(Actual__get_Value(this$.fields[0]), prefix));
        }
        default: {
            const a = this$.fields[0][1];
            const patternInput = Value__Simple_Z721C83C5(Nominal__get_Value(this$.fields[0][0]), prefix);
            const nominalSimpleValue = patternInput[1];
            const nominalName = patternInput[0];
            if (a == null) {
                return toList(delay(() => singleton_1([toText(printf("nominal %s"))(nominalName), nominalSimpleValue])));
            }
            else {
                const actual = a;
                return toList(delay(() => {
                    const patternInput_1 = Value__Simple_Z721C83C5(Actual__get_Value(actual), prefix);
                    return append(singleton_1([toText(printf("actual %s"))(patternInput_1[0]), patternInput_1[1]]), delay(() => singleton_1([toText(printf("nominal %s"))(nominalName), nominalSimpleValue])));
                }));
            }
        }
    }
}

export function EntryValue__get_Value(this$) {
    switch (this$.tag) {
        case 1: {
            return Actual__get_Value(this$.fields[0]);
        }
        case 2: {
            return Actual__get_Value(this$.fields[0]);
        }
        default: {
            return Nominal__get_Value(this$.fields[0][0]);
        }
    }
}

export function EntryValue__Update(this$, updater, u) {
    switch (this$.tag) {
        case 1: {
            return new EntryValue(1, Actual__Update(this$.fields[0], updater, u));
        }
        case 2: {
            return new EntryValue(2, Actual__Update(this$.fields[0], updater, u));
        }
        default: {
            const n = this$.fields[0][0];
            const a = this$.fields[0][1];
            if (a == null) {
                return new EntryValue(0, [Nominal__Update(n, updater, u), a]);
            }
            else {
                return new EntryValue(0, [n, Actual__Update(a, updater, u)]);
            }
        }
    }
}

export function EntryValue__get_Name(this$) {
    return Value__get_Name(EntryValue__get_Value(this$));
}

export function EntryValue__get_Valid(this$) {
    return Value__get_Valid(EntryValue__get_Value(this$));
}

export function EntryValue__get_Encode(this$) {
    let pattern_matching_result, v;
    switch (this$.tag) {
        case 2: {
            pattern_matching_result = 0;
            v = this$.fields[0];
            break;
        }
        case 0: {
            pattern_matching_result = 1;
            break;
        }
        default: {
            pattern_matching_result = 0;
            v = this$.fields[0];
        }
    }
    switch (pattern_matching_result) {
        case 0: {
            return object([["type", name_1(getUnionFields(this$, EntryValue$reflection())[0])], ["value", Value__get_Encode(Actual__get_Value(v))]]);
        }
        case 1: {
            return object([["type", name_1(getUnionFields(this$, EntryValue$reflection())[0])], ["nominal", Value__get_Encode(Nominal__get_Value(this$.fields[0][0]))], ["actual", option_1((av) => Value__get_Encode(Actual__get_Value(av)))(this$.fields[0][1])]]);
        }
    }
}

export function EntryValue_Decoder(path, value) {
    return object_1((get$) => {
        const typ = get$.Required.Raw((path_2, value_2) => field("type", (path_1, value_1) => string(path_1, value_1), path_2, value_2));
        switch (typ) {
            case "Prefilled": {
                return new EntryValue(0, [new Nominal(0, get$.Required.Field("nominal", (arg00_1, arg10_1) => Value_Decoder(arg00_1, arg10_1))), map((arg0) => (new Actual(0, arg0)), get$.Required.Field("actual", (path_3, value_3) => option_2((arg00_3, arg10_3) => Value_Decoder(arg00_3, arg10_3), path_3, value_3)))]);
            }
            case "Measured": {
                return new EntryValue(1, new Actual(0, get$.Required.Field("value", (arg00_5, arg10_5) => Value_Decoder(arg00_5, arg10_5))));
            }
            case "Free": {
                return new EntryValue(2, new Actual(0, get$.Required.Field("value", (arg00_7, arg10_7) => Value_Decoder(arg00_7, arg10_7))));
            }
            default: {
                const message = toText(printf("Expected type EntryValue case  but got %O"))(typ);
                throw (new Error(message));
            }
        }
    }, path, value);
}

export class Entry extends Record {
    constructor(Entry, Description) {
        super();
        this.Entry = Entry;
        this.Description = Description;
    }
}

export function Entry$reflection() {
    return record_type("DataDictionary.Entry", [], Entry, () => [["Entry", EntryValue$reflection()], ["Description", option_type(string_type)]]);
}

export function Entry__get_Encode(this$) {
    return object([["Entry", EntryValue__get_Encode(this$.Entry)], ["Description", option_1((value) => value)(this$.Description)]]);
}

export function Entry_get_Decoder() {
    return (path_2) => ((v) => object_1((get$) => (new Entry(get$.Required.Field("Entry", (arg00_1, arg10_1) => EntryValue_Decoder(arg00_1, arg10_1)), get$.Required.Field("Description", (path_1, value_1) => option_2((path, value) => string(path, value), path_1, value_1)))), path_2, v));
}

export function updateName(name, value) {
    if (value.tag === 0) {
        const nv = value.fields[0];
        return new Value(0, new Number$(name, nv.Value, nv.Unit));
    }
    else {
        return new Value(1, new String$(name, value.fields[0].Value));
    }
}

export function updateValue(value$0027, value) {
    if (value.tag === 0) {
        return new Value(0, value.fields[0]);
    }
    else {
        return new Value(1, new String$(value.fields[0].Name, value$0027));
    }
}

export function updateNumberValue(value$0027, value) {
    if (value.tag === 0) {
        const nv = value.fields[0];
        return new Value(0, new Number$(nv.Name, value$0027, nv.Unit));
    }
    else {
        return new Value(1, value.fields[0]);
    }
}

export function updateUnit(unit, value) {
    if (value.tag === 0) {
        const nv = value.fields[0];
        return new Value(0, new Number$(nv.Name, nv.Value, unit));
    }
    else {
        return new Value(1, value.fields[0]);
    }
}

export function updateValueList(valueList, name, updater, u) {
    return map_2((tupledArg) => {
        const name$0027 = tupledArg[0];
        const value = tupledArg[1];
        if (name$0027 === name) {
            return [name$0027, new Entry(EntryValue__Update(value.Entry, updater, u), value.Description)];
        }
        else {
            return [name$0027, value];
        }
    }, valueList);
}

export function updateValues(name, updater, values) {
    return (u) => updateValueList(values, name, updater, u);
}

export function updateElement(key, elm, st) {
    return map_2((tupledArg) => {
        const k = tupledArg[0];
        if (equals(k, key)) {
            return [k, elm];
        }
        else {
            return [k, tupledArg[1]];
        }
    }, st);
}

export function updateElementF(key, f, st) {
    return map_2((tupledArg) => {
        const k = tupledArg[0];
        const v = tupledArg[1];
        if (equals(k, key)) {
            return [k, f(v)];
        }
        else {
            return [k, v];
        }
    }, st);
}

export class FakeTuple extends Record {
    constructor(RowId, VanityName) {
        super();
        this.RowId = RowId;
        this.VanityName = VanityName;
    }
}

export function FakeTuple$reflection() {
    return record_type("DataDictionary.FakeTuple", [], FakeTuple, () => [["RowId", class_type("System.Guid")], ["VanityName", option_type(string_type)]]);
}

