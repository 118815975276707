import { Union, Record } from "../.fable/fable-library.3.1.11/Types.js";
import { CSSProp$reflection } from "../.fable/Fable.React.7.3.0/Fable.React.Props.fs.js";
import { union_type, bool_type, lambda_type, unit_type, class_type, string_type, record_type, list_type } from "../.fable/fable-library.3.1.11/Reflection.js";
import { isEmpty } from "../.fable/fable-library.3.1.11/List.js";
import { exactlyOne, isEmpty as isEmpty_1, choose } from "../.fable/fable-library.3.1.11/Seq.js";
import { keyValueList } from "../.fable/fable-library.3.1.11/MapUtil.js";
import { some } from "../.fable/fable-library.3.1.11/Option.js";
import * as react from "react";
import { IconButton as IconButton_1, CommandBarButton as CommandBarButton_1, DefaultButton as DefaultButton_1, ActionButton as ActionButton_1 } from "office-ui-fabric-react/lib/Button";

export class Styles extends Record {
    constructor(root) {
        super();
        this.root = root;
    }
}

export function Styles$reflection() {
    return record_type("FabricUi.Button.Styles", [], Styles, () => [["root", list_type(CSSProp$reflection())]]);
}

export class Props extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["ClassName", "OnClick", "Text", "Checked", "Disabled", "AllowDisabledFocus", "Href", "MenuProps", "MenuIconProps", "IconProps", "Primary", "Target", "Download", "Styles"];
    }
}

export function Props$reflection() {
    return union_type("FabricUi.Button.Props", [], Props, () => [[["Item", string_type]], [["Item", lambda_type(class_type("Browser.Types.MouseEvent"), unit_type)]], [["Item", string_type]], [["Item", bool_type]], [["Item", bool_type]], [["Item", bool_type]], [["Item", string_type]], [["Item", class_type("FabricUi.ContextualMenu.IContextualMenuItem")]], [["Item", class_type("FabricUi.Icon.IIconProps")]], [["Item", class_type("FabricUi.Icon.IIconProps")]], [["Item", bool_type]], [["Item", string_type]], [["Item", string_type]], [["Item", Styles$reflection()]]]);
}

export function pprops(props) {
    if (isEmpty(props)) {
        return {};
    }
    else {
        let customProps;
        const collectedProps = choose((_arg1) => {
            if (_arg1.tag === 13) {
                return some({
                    styles: {
                        root: keyValueList(_arg1.fields[0].root, 1),
                    },
                });
            }
            else {
                return void 0;
            }
        }, props);
        customProps = ((!isEmpty_1(collectedProps)) ? exactlyOne(collectedProps) : {});
        const typeProps = keyValueList(choose((_arg2) => {
            if (_arg2.tag === 13) {
                return void 0;
            }
            else {
                return _arg2;
            }
        }, props), 1);
        return Object.assign({}, customProps, typeProps);
    }
}

export function ActionButton(props) {
    return react.createElement(ActionButton_1, pprops(props));
}

export function DefaultButton(props) {
    return react.createElement(DefaultButton_1, pprops(props));
}

export function CommandBarButton(props) {
    return react.createElement(CommandBarButton_1, pprops(props));
}

export function IconButton(props) {
    return react.createElement(IconButton_1, pprops(props));
}

