import { Record } from "../.fable/fable-library.3.1.11/Types.js";
import { record_type, class_type, option_type, array_type, string_type } from "../.fable/fable-library.3.1.11/Reflection.js";

export class AuthenticationParameters extends Record {
    constructor(scopes, extraScopesToConsent, prompt, extraQueryParameters, claimsRequest, authority, state, correlationId, account, sid, loginHint) {
        super();
        this.scopes = scopes;
        this.extraScopesToConsent = extraScopesToConsent;
        this.prompt = prompt;
        this.extraQueryParameters = extraQueryParameters;
        this.claimsRequest = claimsRequest;
        this.authority = authority;
        this.state = state;
        this.correlationId = correlationId;
        this.account = account;
        this.sid = sid;
        this.loginHint = loginHint;
    }
}

export function AuthenticationParameters$reflection() {
    return record_type("AuthenticationParameters.AuthenticationParameters", [], AuthenticationParameters, () => [["scopes", option_type(array_type(string_type))], ["extraScopesToConsent", option_type(array_type(string_type))], ["prompt", option_type(string_type)], ["extraQueryParameters", option_type(class_type("AuthenticationParameters.QPDict"))], ["claimsRequest", option_type(string_type)], ["authority", option_type(string_type)], ["state", option_type(string_type)], ["correlationId", option_type(string_type)], ["account", option_type(class_type("Account.Account"))], ["sid", option_type(string_type)], ["loginHint", option_type(string_type)]]);
}

