import DateOffset from "./.fable/fable-library.3.1.11/DateOffset.js";
import { toNumber } from "./.fable/fable-library.3.1.11/Long.js";
import { Union, Record } from "./.fable/fable-library.3.1.11/Types.js";
import { union_type, record_type, list_type, tuple_type, option_type, string_type, class_type } from "./.fable/fable-library.3.1.11/Reflection.js";
import { EntityType$reflection } from "./Shared/Entity.fs.js";
import { Cmd_none } from "./.fable/Fable.Elmish.3.1.0/cmd.fs.js";
import { EntityRoute, navigateTo } from "./Route.fs.js";
import { entityMetaFromType } from "./Data.fs.js";
import { printf, toText, isNullOrEmpty } from "./.fable/fable-library.3.1.11/String.js";
import { isEmpty, empty, ofArray, toArray, singleton, filter as filter_1 } from "./.fable/fable-library.3.1.11/List.js";
import { defaultArg, map } from "./.fable/fable-library.3.1.11/Option.js";
import { Styles, StackProps, Stack } from "./fabric/Stack.fs.js";
import { SearchBoxProp, SearchBox, ListProp$1, FabricList } from "./fabric/FabricUI.fs.js";
import * as react from "react";
import { Pad10, itemCell } from "./fabric/MergeStyle.fs.js";
import { Props, Icon } from "./fabric/Icon.fs.js";
import { Browser_Types_Event__Event_get_Value } from "./.fable/Fable.React.7.3.0/Fable.React.Extensions.fs.js";
import { CommandBar_CommandBarItemProps, CommandBar_CommandBarProps, CommandBar_CommandBar } from "./fabric/CommandBar.fs.js";
import { CSSProp } from "./.fable/Fable.React.7.3.0/Fable.React.Props.fs.js";

export function toTime(timeStamp) {
    return DateOffset(toNumber(timeStamp) * 1000, 0);
}

export class Model extends Record {
    constructor(Entities, EntityType, Filter) {
        super();
        this.Entities = Entities;
        this.EntityType = EntityType;
        this.Filter = Filter;
    }
}

export function Model$reflection() {
    return record_type("Entities.Model", [], Model, () => [["Entities", list_type(tuple_type(class_type("System.Guid"), option_type(string_type)))], ["EntityType", EntityType$reflection()], ["Filter", Search$reflection()]]);
}

export class Search extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["NoSearch", "Filter"];
    }
}

export function Search$reflection() {
    return union_type("Entities.Search", [], Search, () => [[], [["Item", tuple_type(string_type, list_type(tuple_type(class_type("System.Guid"), option_type(string_type))))]]]);
}

export class Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Filter", "Clear", "ViewEntity"];
    }
}

export function Msg$reflection() {
    return union_type("Entities.Msg", [], Msg, () => [[["Item", string_type]], [], [["Item", class_type("System.Guid")]]]);
}

export function initialModel(entities, initialType) {
    return new Model(entities, initialType, new Search(0));
}

export function update(msg, model) {
    let filter;
    switch (msg.tag) {
        case 1: {
            return [new Model(model.Entities, model.EntityType, new Search(0)), Cmd_none()];
        }
        case 2: {
            return [model, navigateTo(entityMetaFromType(model.EntityType).route(new EntityRoute(1, msg.fields[0])))];
        }
        default: {
            const filterstring = msg.fields[0];
            if (isNullOrEmpty(filterstring)) {
                return [new Model(model.Entities, model.EntityType, new Search(0)), Cmd_none()];
            }
            else {
                return [new Model(model.Entities, model.EntityType, new Search(1, [filterstring, filter_1((filter = filterstring.toLocaleLowerCase(), (i) => {
                    const maybeF = map((j) => (j.toLocaleLowerCase().indexOf(filter) >= 0), i[1]);
                    return (maybeF == null) ? false : maybeF;
                }), model.Entities)])), Cmd_none()];
            }
        }
    }
}

export function viewSamples(model, dispatch) {
    let matchValue;
    return Stack(singleton(new StackProps(5, true)), singleton(FabricList(ofArray([new ListProp$1(0, toArray((matchValue = model.Filter, (matchValue.tag === 1) ? matchValue.fields[0][1] : model.Entities))), new ListProp$1(1, (item) => {
        let copyOfStruct;
        return react.createElement("div", {
            className: itemCell.itemCell,
            onClick: (ev) => {
                dispatch(new Msg(2, item[0]));
            },
        }, Stack(ofArray([new StackProps(4, "center"), new StackProps(3, "space-between"), new StackProps(1, true)]), ofArray([Stack(empty(), singleton(react.createElement("h2", {}, defaultArg(item[1], (copyOfStruct = item[0], copyOfStruct))))), Icon(Props("ChevronRight", "ChevronRight"), empty())])));
    })]))));
}

export function searchbox(model, et, dispatch, unitVar3) {
    let value;
    const matchValue = model.Filter;
    value = ((matchValue.tag === 1) ? matchValue.fields[0][0] : "");
    const meta = entityMetaFromType(et);
    return SearchBox(ofArray([new SearchBoxProp(0, toText(printf("Search %s by name"))(meta.name)), new SearchBoxProp(1, value), new SearchBoxProp(4, {
        root: {
            ["align-self"]: "center",
        },
    }), new SearchBoxProp(3, () => {
        dispatch(new Msg(1));
    }), new SearchBoxProp(2, (ev) => {
        dispatch(new Msg(0, Browser_Types_Event__Event_get_Value(ev)));
    })]));
}

export function sampleCommandBar(dispatch, model, et) {
    return CommandBar_CommandBar(ofArray([new CommandBar_CommandBarProps(6, "SampleCommandBar"), new CommandBar_CommandBarProps(0, toArray(empty())), new CommandBar_CommandBarProps(1, toArray(singleton(CommandBar_CommandBarItemProps("Search stuff", "", void 0, false, "searchdevices", void 0, () => searchbox(model, et, dispatch, void 0), false, void 0))))]));
}

export function view(model, dispatch) {
    if (!isEmpty(model.Entities)) {
        return Stack(ofArray([new StackProps(5, true), new StackProps(13, new Styles(ofArray([new CSSProp(272, "auto"), new CSSProp(189, "100%")])))]), ofArray([react.createElement("div", {
            className: Pad10.Pad10,
        }, react.createElement("h1", {}, entityMetaFromType(model.EntityType).name)), sampleCommandBar(dispatch, model, model.EntityType), viewSamples(model, dispatch)]));
    }
    else {
        return Stack(singleton(new StackProps(5, true)), singleton(Stack(ofArray([new StackProps(5, true), new StackProps(3, "center"), new StackProps(4, "center")]), ofArray([react.createElement("h1", {}, "⏳"), react.createElement("h1", {}, "No data yet "), "To create a new wafer click on the plus button !"]))));
    }
}

